export default {
  locales: {
    en: "English",
  },
  fatca: "FATCA",
  crs: "CRS",
  acttrader: "ActTrader",
  compairacc: "Compare Accounts",
  ecnacc: "ECN ACCOUNT",
  openacc: "Open Account",
  crypto: "Cyrptocurrencies",
  stock: "Stocks",
  forex: "Forex",
  indices: "Indices",
  commodities: "Commodities",
  negativebalance: "Negative Balance Protection",
  minorderdest: "Min.order Distance",
  stopout: "stop-out",
  margincall: "Margin Call",
  yes: "Yes",
  no: "No",
  lot: "lot",
  usd: "USD",
  eur: "EUR",
  gbp: "GBP",
  aud: "AUD",
  basecurrency: "Base currencies",
  upto: "up to",
  mindeposit: "Min. Deposit",
  account: "Account",
  zulu: `zulu`,
  zulu_trade: "Zulu Trade",
  plus: "plus",
  max: "max",
  min: "min",
  leverage: "Leverage",
  per: "per",
  commission: "Commission",
  minpip: "min. pip",
  spreads: "Spreads",
  spread: "Spread",
  ecn: "ECN",
  keyinfo: "Key Info Docs",
  mifid: "MIFID Legislation",
  privacypolicy: "Privacy Policy",
  contact: "Contact 24/5",
  webtrader: "Web-Trader",
  login: "Login",
  joinNow: "Join Now",
  aboutAAAfx: {
    name: "About AAAFx",
    submenu: {
      why: {
        title: "Why AAAFx",
        tagline: "Know who we are",
      },
      safety: {
        title: "Safety of Funds",
        tagline: "We have got your back",
      },
      servers: {
        title: "Our Servers",
        tagline: "Best systems for best results",
      },
      regulations: {
        title: "Regulations and Licenses",
        tagline: "Reliable and Secure Trading",
      },
      legalDocs: {
        title: "Legal Documents",
        tagline: "Read all Legal Documents",
      },
      careers: {
        title: "Careers",
        tagline: "Join our Team",
      },
      contactUs: {
        title: "Contact Us",
        tagline: `We'd love to hear from you`,
      },
    },
    title:
      "Triple A Experts Investment Services Single Member SA is EU authorized and regulated by the Hellenic Capital Market Commission (id:2/540/17.2.2010) with registered address at Triple A Experts Investment Services Single Member S. A, 580 Vouliagmenis Avenue 16452, Argyroupoli Attica, Greece. And Registration Authority Entity ID 4480770 7000.",
  },
  trading: {
    name: "Trading",
    submenu: {
      products: {
        title: "Trading Products",
        tagline: "Trading Instruments we offer",
      },
      accountTypes: {
        title: "Accounts Types",
        tagline: "Choose what suits your requirements",
      },
      tradingConditions: {
        title: "Trading Conditions",
        tagline: "Everything to suit your best interests",
      },
      depWithdrawal: {
        title: "Deposits & Withdrawals",
        tagline: "Hassle Free money management",
      },
    },
  },
  platforms: {
    name: "Platforms",
    submenu: {
      mt4: {
        title: "MT4",
        tagline: "Most popular trading platform",
      },
      mt5: {
        title: "MT5",
        tagline: "Advanced and Multi functional",
      },
      act: {
        name: "ACT",
        title: "ActTrader",
        tagline: "Easy to use and most convenient",
      },
      comparison: {
        title: "Comparison of Platforms",
        tagline: "Make an informed decision",
      },
    },
  },
  promotions: {
    name: "Promotions",
    submenu: {
      bonus: {
        title: "Deposit Bonus",
        tagline: "Double your margins at no cost",
      },
      refer: {
        title: "Refer & Earn",
        tagline: "Invite your friends and share the benefits",
      },
      vps: {
        title: "Free VPS",
        tagline: "Avail free high speed trading",
      },
    },
  },
  tools: {
    name: "Tools & Resources",
    submenu: {
      zulu: {
        title: "ZuluTrade",
        tagline: "Join the largest social trading network",
      },
      calculators: {
        title: "Trading Calculators",
        tagline: "Calculations made easy for you",
      },
      ecoCalendar: {
        title: "Economic Calendar",
        tagline: "Stay updated and trade wisely",
      },
      partnerships: {
        title: "Partnerships",
        tagline: "Partner with us and grow",
      },
      faq: {
        title: "FAQs",
        tagline: "Get answers to common questions",
      },
    },
  },
  legalinfo: {
    name: "Legal Information",
    para1: `Triple A Experts Investment Services Single Member SA is EU authorized and regulated by the Hellenic Capital Market Commission (id:2/540/17.2.2010) with registered address at Triple A Experts Investment Services S.A., 14 Akti Kondyli street, 18545 Piraeus, Greece.`,
    para2: `Sikhula Venture Capital (Pty) Ltd. is authorized and regulated by the South African Financial Sector Conduct Authority (FSCA No. 2017/315029/07).`,
    para3: `www.aaafx.com is operated by Triple A Experts Investment Services Single Member SA`,
    para4: `www.aaafx.com is operated by Triple A Experts Investment Services Single Member SA.`,
  },
  riskdisc: {
    name: "Risk Disclosure",
    para_one: `CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage.`,
    para_two: `of retail investor accounts lose money when trading CFDs with AAAFx. You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money. Details can be found`,
    link: `here.`,
    acceptedLang: "Acceptable Countries",
    countries:"Triple A Experts Investment Services Single Member S.A accept clients from 27 countries under the European Economic Area (EU and Iceland, Switzerland and Liechtenstein) Clients from Belgium and the UK are not accepted.",
  },
  accounttypepage: {
    title: "Account Types",
    tagline1: `Choose the account that suits your trading needs! We have exciting offerings for`,
    tagline2: `every trader, in a highly simplified manner.`,
    zeroPointZero: "0.0",
    hundredDollar: "$100k",
    fiveDollar: "$5",
    threeDollar: "$3",
    thirtyX: "30X",
    hundered: "$100",
    tenThousand: "$10,000",
    chf: "CHF",
    seventyFifty: "70/50%",
    para1: {
      desc1: `There are three different account types, that match the requirements of a novice trader, a professional trader, or an institutional trader. Some of the common exciting features assured in all the accounts are:`,
      point1: `EU Regulated Broker`,
      point2: `Ultra -Thin spreads`,
      point3: `All trading strategies allowed`,
      point4: `No Min. Order distance-place Orders as you like`,
      point5: `Negative Balance Protection`,
      point6: `Micro Lots Trading`,
      desc2: `Without any discrimination we offer institutional grade spreads and liquidity to all traders alike.`,
    },
    accdesc: {
      title: "Account Description",
      point1: {
        title: "ECN Account",
        desc: `Enjoy raw ECN spreads and lightning-fast trade execution at very low cost. Low commission charges and low swap charges combined with our advanced technology make your trading experience exemplary in every sense. We do not impose any restriction on any trading strategy either.`,
      },
      point2: {
        title: "ECN plus Account",
        desc: `For traders with minimum deposit of $10,000 or those in AAA category in Loyalty Program, ECN zero is a reward to long for. This is the best combination possible for any trader- Zero Commission on Forex with raw thin spreads, at no mark up. You can enjoy all of this along with our lightning-fast trade execution.`,
      },
      point3: {
        title: "ECN Zulu Trade Account",
        desc: `Become part of the largest social trading community by opening a Zulu Account with us. This account is automatically connected to the Zulu Platform, no further action is required. Hence, you automatically get to enjoy razor thin spreads, low commission, our advanced technology, deep liquidity, and lightning-fast execution, along with direct access to world class trading community.`,
      },
      para1: `More flexible trading conditions (increased leverage) may apply in case of clients classified as Professional. Please log in to your account and check eligibility for Professional Client, classification, or write to us at`,
    },
    supportEmail: "support{'@'}aaafx.com",
  },
  acttraderpage: {
    title: "ActTrader",
    para1:
      "Want to trade but unsure on learning the advanced trading platforms?",
    para2: ` We have got you covered with our ActTrader trading platform. ActTrader has been the front runner in the online trading industry since 2000, equipped with the best-in class technology. The platform focuses on simplicity, readability, and challenging the existing trading traditions with robust technology such that the user can vividly focus on the trading options. ActTrader has witnessed a multi-fold increase in the trading volume up to $5 trillion per month, substantiating the deep liquidity on the platform.`,
    para3: `Begin your smooth trading journey with ActTrader:`,
    questionnaire: {
      question1: {
        heading: "Steps to download ActTrader Desktop",
        point: "1. Download the platform from above link.",
        point1: "2. Run the .exe file",
        point2:
          "3. Enter your trading account number and password on your ACT platform.",
        point3:
          " 4. Select the account type : Demo for demo account / Real for live account.",
        point4: "5. Select your preferred language.",
        point5: "6. Click on “login”",
        point6: "7. A small box will pop up. Select the time zone.",
        point7:
          "8. Again, a small box will pop up. Select the layout of the platform beginner / advanced.",
      },
      question2: {
        heading: "Steps to uninstall ActTrader Desktop",
        point: "1. Click on Start button in Windows",
        point1: "2. The ACT application will be included in list of programs.",
        point2:
          "3. Right click on the application name and select ‘Uninstall’.",
      },
      question3: {
        heading: "Steps to log into Web-based Act Trader",
        point: "1. Click on the Web Trader link",
        aTag: "here",
        point1:
          '2. Select "Demo web trader" for demo account or "Live web trader" for live account.',
        point2: "3. A new tab will open.",
        point3: "4. Fill in the login credentials. You are now logged in.",
      },
      question4: {
        heading: "Steps to download ActTrader Android",
        point: "1. Install the application from the above link.",
        point1: "2. Click on the installed application on your android device.",
        point2: "3. Press “Agree” on the license agreement.",
        point3:
          "4. You need to enter your trading account number on username and it’s password.",
        point4:
          "5. Select account type :AAAFX Demo for demo and AAAFX Live for live.",
        point5: "6. Click on “login”",
      },
      question5: {
        heading: "Steps to uninstall ActTrader Android",
        point:
          "1. On the Menu, go to Settings > Applications > Manage applications",
        point1: "2. Find ACT listed in there and tap on its name.",
        point2: "3. Tap Uninstall.",
      },
      question6: {
        heading: "Steps to download ActTrader iOS",
        point: "1. Open ActPhone app.",
        point1: "2. Enter the login credentials shared with you via email.",
        point2:
          "3. In Account type, scroll down and select AAAFX Demo for a Demo account or AAAFX Live for a Live account.",
        point3: "4. Select Sign In and wait until your account loads.",
      },
      question7: {
        heading: "Steps to uninstall ActTrader iOS",
        point:
          "1. Long press on the ActTrader application icon until all the icons on your screen begin to shake.",
        point1:
          '2. Click the "x" in the upper left-hand corner of the icon for the ActTrader application.',
      },
    },
    journey: "Begin your smooth trading journey with ActTrader:",
    heading:
      "AAAFx ActTrader promises to allow a user to explore a multitude of trading opportunities with tailor-made conditions and the ability to overview everything from scratch.",
    features: "Key features of AAAFx ActTrader platform:",
    platform:
      "Still hesitant? Have a look at the brief overview of the features provided over the ActTrader platform:",
    depth: "In-depth analytical tools:",
    ul: {
      li: "Wide variety of already installed indicators",
      li1: "Multiple drawing tools to use over the charts",
      li2: "Simplified working with one-click trading option",
      li3: "Set-up key price alerts so that you never miss out on any opportunity",
      li4: "Export account’s data directly to excel with utmost convenience",
      liTag: "Add/remove additional workspace",
      liTag1: "Format the date/time settings",
      liTag2:
        "Add colors to your trading terminal and make a fun workspace for yourself",
      liTag3: "Stream platform prices the way you like",
      liTag4: "70+ forex currencies",
      liTag5: "Popular commodities including gold and oil",
      liTag6: "11 global indices- Nasdaq, Dow Jones, etc.",
      liTag7: "6 major and most transacted crypto currencies",
    },
    tradingWindow: "Personalize your trading window:",
    instument: "Instruments offered:",
    liveAccount: "OPEN LIVE ACCOUNT",
    demoAccount: "OPEN DEMO ACCOUNT",
  },
  loginPage: {
    h3Tag: "Please wait. Redirecting to your account",
  },
  compensation: {
    title: "Compensation Fund",
    questions: {
      ques: "What is The Financial Commission?",
      ans: "The Financial Commission is a uniquely formed independent body to make traders feel safe and guarded. The main motive of the Financial Commission is to bring about fast and effective resolutions of disputes between traders and brokers. It operates in a way where transparency, swiftness, and education are the paramount values. All Financial Commission services are absolutely free for our clients.",
      ques1: "How does it work?",
      ans1: "A Compensation Fund is financed by the Financial Commission through the allocation of 10% of the membership dues to the fund which are held in a separate bank account and ctannot be used to finance operations or other activities of the organization. The Fund provides a safety net to AAAFx clients of up to EUR 20,000 for a single complaint.",
      ques2: "Why we joined the Financial Commission?",
      ans2: "The Financial Commission has been brought together by some of the leading industry specialists whose primary aim is to increase market transparency and service standards across the Forex industry. This is in line with what we truly believe in at AAAFx  honesty and transparency. Keeping clients at the center of our business is one of our core ideologies. Hence, we joined hands with the Financial Commission to provide effective and timely dispute resolution to our clients.",
    },
    steps: "What are the steps of Dispute",
    process: "Resolution process?",
    complaint: "Complaint",
    pTag: "A client can contact the Commission if they",
    brTag: "have received an official response from the",
    brTag1: "broker regarding an unresolved dispute that",
    brTag2: "the client deems unsatisfactory.",
    investigation: "Investigation",
    pTag1: "Once their Dispute Resolution Committee",
    brTag3: "has received the complaint, investigation and",
    brTag4: "research begins and a decision is made",
    brTag5: "within 1-2 weeks.",
    text: "Compensation",
    pTag2: "Where brokers refuse to comply with decisions",
    brTag6: "taken by the Financial Commission, a",
    brTag7: "compensation is paid to the trader from the special",
    brTag8: "compensation fund.",
    decision: "Decision",
    resolutionRegarding: "The Committees Resolution regarding",
    brTag9: "the assessment of dispute is binding on",
    brTag10: "the broker and must be complied with by",
    brTag11: "the broker.",
  },
  zuluTools: {
    title: "ZULUTRADE",
    pTag: "Do not trade alone- Join the largest social trading network",
    para: "At AAAFx, you get a chance to trade institutional grade liquidity and make use of our superior trading conditions. However, trading can sometimes be highly taxing on your time as well as on your emotions, which can be hindrance to a successful trading experience for some people.",
    bTag: "At AAAFx, you get a chance to trade institutional grade liquidity and make use of our superior trading conditions. However, trading can sometimes be highly taxing on your time as well as on your emotions, which can be hindrance to a successful trading experience for some people.",
    bTag1:
      "Incorporated in 2007, ZuluTrade helps investors in discovering exceptional traders from around the globe and copying their strategies to trade for themselves. This is a social and copy trading online platform which allows users to adopt expert plan of action for their trades. It ranks the experts performance and allows the trader to readily notice and copy trades from their portfolio. Today, it is one of the most popular social trading networks.",
    copier: "Become a Copier",
    pTag1:
      "With our ZuluTrade Account, you get direct and automatic and direct access to a plethora of opportunities for making profits through Zulu connection.",
    brTag:
      "You can simply review the trading performance of Expert traders, copy them, and just relax!",
    register: "REGISTER",
    master: "Become a Master",
    pTag2:
      "If you want to become a Master trader and want others to see and copy your trading expertise, you get that option too with us. Your AAAFx account is automatically connected to Zulu.",
    brTag1:
      "So, you get to earn profits in your trading account and earn performance fees from your copiers too!",
    offers:
      "ZuluTrade offers a variety of features and products, some of them are listed below:",
    platform: "ZuluTrade Social Platform:",
    pTag3:
      "Whether you are a trader and have a successful trading strategy to share, or you are an investor and want to discuss Zulu Traders and their performance, here is a one stop forum. ZuluTrade has an extensive social trading community platform, that includes blogs, Trading Discussions and Social Feed. This means a chance to interact with the trading community and learn from them as well.",
    zuluGuard: "ZuluTrade Guard:",
    pTag4:
      "ZuluTrade Guard offers account protection by monitoring the expert’s behavior and unfollows the signal provider in case any alterations are made in the trading strategy.",
    brTag2:
      "It does so by calculating an exit value for the open trades in the account and when the threshold is hit, trades are closed",
    automator: "Automator:",
    pTag5:
      "Automator stands for Automated Trading. It refers to trading through pre-defined rules for entry and exit of the trades performed by an automated program. It helps in elimination of trading emotions and saves time.",
    combos: "Combos:",
    pTag6:
      "Traders are grouped by ZuluTrade in combinations based on their risk appetites and strategies. The investor can choose a combo to follow. Each combo is managed by Zulu Trade’s Certified Management Portfolio Team.",
    quickSteps: "Three quick steps to begin copying:",
    openFund: "Open and Fund your AAAFx",
    zuluAccount: "ECN zulu Account",
    cred: "Get Zulu log in credentials in email",
    zuluTrade: "Start copying trades from Zulu Trade",
    openLive: "OPEN LIVE ACCOUNT",
    openDemo: "OPEN DEMO ACCOUNT",
  },
  zuluTrade: {
    mt4: "MT4",
    zulutrade: "ZuluTrade",
    h5Tag:
      "People tend to perform better in communities and by following those who can be relied on.",
    pTag: "AAAfx understands this need and thereby offers the most sought after Zulu Trade Platform. Zulu Trade was founded in 2007, at a time when the world lacked a suitable mechanism to audit trading practices and for sharing its trading knowledge with one another. This platform also eliminated the need to sit hours in front of a screen and introduced automated and professional financial trading to the mainstream.",
    brTag:
      "Zulu Trade is an automated trading platform, which provides a thorough statistical analysis of the top traders in order to allow a user to gain insight and establish a system of copying trades and earning the advantage",
    startTrading: "Start trading in just 3 simple steps:",
    openFund: "Open and Fund your AAAFx",
    ecnPlus: "ECN Zulu Trade Account",
    variousTraders: "Analyse the performance of various traders.",
    copy: "Select the trader you want to copy.",
    pTag1:
      "You can also choose from diversified strategies built from the best combinations of the best traders on Zulu Trade.Choose the Combo+ service and get your account managed by ZuluTrade’s Certified Management Portfolio Team on fully automatic or semi automatic mode, as you wish!",
    ztp: "ZTP is a pure web-based platform which can easily be accessed here",
    webBase: "Web based Platform",
    h3Tag:
      "AAAFx provides exceptional trading experience combined with the world class ZTP’s following features:",
  },
  why: {
    title: "Why AAAFx?",
    honesty: "Par Excellence with Honesty and Transparency",
    believe: "Why Believe in AAAFx?",
    pTag: "We have more than a decade long history of providing our global clients with reliable and excellent service. We establish full transparency with our clients, so that they can make well informed decisions.",
    brTag:
      "We know what it takes to be a successful trader, and hence aim to provide top notch trading conditions and customer support to our clients. We are proud to be one of the most trusted brokers in forex market, serving retail as well as institutional clients.",
    listing: {
      point: "Secure and Reliable Broker",
      point1: "Lightning-fast execution",
      point2: "Zero Commission, Raw Spreads on Forex",
      point3: "Deep liquidity Pool",
      point4: "3 Premium Trading Platforms",
      point5: "Equinix data centres equipped with high-end technology",
      point6: "14 years’ experience",
      point7: "Several methods to make Cost-free Deposits & Withdrawals",
    },
    more: "What’s more?",
    socialTrading: "Auto Connect to the largest social trading community",
    entireTeam: "And the entire AAAFx team instantly at your service!!",
    pledge: "We Pledge...",
    liTag:
      "To supply you with a brokerage service working in the best interests of traders.",
    liTag1:
      "To abide by the Rules published under the relevant regulatory frameworks.",
    liTag2: "To continuously look for opportunities to innovate and improvise.",
    liTag3: "To adhere to our Core Values at all times.",
    founded: "FOUNDED IN",
    serve: "COUNTRIES WE SERVE",
    trade: "Instruments to Trade",
    platform: "Platforms we offer",
    support: "LIVE Support",
    number: {
      twoThousand: "2008",
      thirty: "30",
      twoHundred: "200+",
      three: "3",
      twentyFour: "24/5",
    },
    team: "Our Team...",
    pTag1:
      "Individually, our ideals may appear self-evident. But when we put them all together, we have our own corporate culture. Who we are, what we stand for, and how we act is all shaped by our values.",
    pTag2:
      "Our Management is a team of strong-minded able professionals who have vast experience working on the Wall Street. Hence, they know exactly what it takes to be a successful trader. With their formidable background and business experience, they possess insightful knowledge of all of the industry’s best and worst practices.",
    wallStreet:
      "Strong-willed individuals with extensive wall-street experience",
    innovation: "Always striving for innovation and improvement",
    approach: "Client First Approach,",
    weDo: "in everything we do",
    values: "Our Values, Your success!!",
    pTag3:
      "We are on solid grounds, as we foster a value-based environment in all of our offices, which is driven by our ,management’s strong belief in business ethics.",
    pTag4:
      "We stick to our 5 cores values, so that you can shine in the global trading community.",
    h3Tag: "Happy clients in more than",
    countries: "30 EU countries*",
    taking: ", taking",
    worldClass: "advantage of our world-class services.",
    france: "*Except Belgium and France",
    info: "ΕΝΗΜΕΡΩΤΙΚΑ ΣΤΟΙΧΕΙΑ ΕΤΑΙΡΕΙΑΣ/COMPANY INFORMATION",
    data: "ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ/FINANCIAL DATA",
    date1: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2024",
    date: "ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2024",
    thirtyFirst: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2023",
    thirty: "ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2023",
    financeStatement: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2022",
    financialData: "ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2022",
    statement: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2021",
    aTag: {
      li: "ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2021",
      liTag: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2020",
      liTag1: "ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2020",
      liTag2: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2019",
      liTag3: "ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2019",
      liTag4: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2018",
      liTag5: "ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2018",
      liTag6: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2017",
      liTag7: "ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2017",
      liTag8: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2016",
      liTag9: "ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2016",
      li1: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2015",
      li2: "ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2015",
      li3: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2014",
      li4: "ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2014",
      li5: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2013",
      li6: "ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2013",
      li7: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2012",
      li8: "ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2012",
      li9: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2011",
      liTag10: "ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2011",
      liTag11: "ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2010",
      liTag12:
        "ΙΣΟΛΟΓΙΣΜΟΣ ( ΣΥΝΟΠΤΙΚΑ ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ & ΠΛΗΡΟΦΟΡΙΕΣ ) 31-12-2015",
      liTag13:
        "ΙΣΟΛΟΓΙΣΜΟΣ ( ΣΥΝΟΠΤΙΚΑ ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ & ΠΛΗΡΟΦΟΡΙΕΣ ) 31-12-2014",
      liTag14:
        "ΙΣΟΛΟΓΙΣΜΟΣ ( ΣΥΝΟΠΤΙΚΑ ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ & ΠΛΗΡΟΦΟΡΙΕΣ ) 31-12-2013",
      liTag15:
        "ΙΣΟΛΟΓΙΣΜΟΣ ( ΣΥΝΟΠΤΙΚΑ ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ & ΠΛΗΡΟΦΟΡΙΕΣ ) 31-12-2012",
      liTag16:
        "ΙΣΟΛΟΓΙΣΜΟΣ ( ΣΥΝΟΠΤΙΚΑ ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ & ΠΛΗΡΟΦΟΡΙΕΣ ) 31-12-2011",
      liTag17:
        "ΙΣΟΛΟΓΙΣΜΟΣ ( ΣΥΝΟΠΤΙΚΑ ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ & ΠΛΗΡΟΦΟΡΙΕΣ ) 31-12-2010",
    },
    dropdown: {
      title: "ΕΠΟΠΤΙΚΕΣ ΠΛΗΡΟΦΟΡΙΕΣ/SUPERVISORY INFORMATION",
      aTag2: {
        pillar2024: "Pillar III Disclosure Triple A 2024",
        pillar2023: "2023 Pillar III Market Discipline and Disclosure Report",
        pillar2022: "2022 Pillar III Market Discipline and Disclosure Report",
        pillar2021: "2021 Pillar III Market Discipline and Disclosure Report",
        regulatory2023:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2024_α80&87_Ν4261_2014",
        corporateGovernance2022:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2022_α80&87_Ν4261_2014",
        financial2021:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2021_α80 ΕΩΣ 87_Ν4261_2014",
        financial2020:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ ΜΕ ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2020",
        financial2019:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ ΜΕ ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2019",
        financial2018:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ ΜΕ ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2018",
        financial2017:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ ΜΕ ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2017",
        financial2016:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ ΜΕ ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2016",
        financial2015:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ ΜΕ ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2015",
        financial2014:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΑΠΟΦΑΣΗΣ ΕΚ 9 459-27/12/2007 ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2014",
        financial2013:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΑΠΟΦΑΣΗΣ ΕΚ 9 459-27/12/2007 ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2013",
        financial2012:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΑΠΟΦΑΣΗΣ ΕΚ 9 459-27/12/2007 ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2012",
        financial2011:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΑΠΟΦΑΣΗΣ ΕΚ 9 459-27/12/2007 ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2011",
        financial2010:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΑΠΟΦΑΣΗΣ ΕΚ 9 459-27/12/2007 ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2010",
        details2020:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2020_α80 ΕΩΣ 87_Ν4261_2014",
        details2019:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2019_α80 ΕΩΣ 87_Ν4261_2014",
        details2018:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2018_α80 ΕΩΣ 87_Ν4261_2014",
        details2017:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2017_α80 ΕΩΣ 87_Ν4261_2014",
        details2016:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2016_α80 ΕΩΣ 87_Ν4261_2014",
        details2015:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2015_α81&82_Ν4261_2014",
        details2014b:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2015_α80 ΕΩΣ 87_Ν4261_2014",
        details2014:
          "ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2014_α81&82_Ν4261_2014",
      },
    },
    announcment: "ΑΝΑΚΟΙΝΩΣΕΙΣ/ANNOUNCEMENTS",
    aTag3: {
      executionVenues2024:"2024 ΤΟΠΟΙ ΕΚΤ. ΑΝΑ ΚΑΤΗΓ. ΧΡΗΜ. ΜΕΣΟΥ/2024 EXECUTION VENUES",
      executionVenues2023:
        "2023 ΤΟΠΟΙ ΕΚΤ. ΑΝΑ ΚΑΤΗΓ. ΧΡΗΜ. ΜΕΣΟΥ/2023 EXECUTION VENUES",
      executionVenues2022:
        "2022 ΤΟΠΟΙ ΕΚΤ. ΑΝΑ ΚΑΤΗΓ. ΧΡΗΜ. ΜΕΣΟΥ/2022 EXECUTION VENUES",
      executionVenues2021:
        "2021 ΤΟΠΟΙ ΕΚΤ. ΑΝΑ ΚΑΤΗΓ. ΧΡΗΜ. ΜΕΣΟΥ/2021 EXECUTION VENUES",
      executionVenues2020:
        "2020 TΟΠΟΙ ΕΚΤ. ΑΝΑ ΚΑΤΗΓ. ΧΡΗΜ. ΜΕΣΟΥ/2020 EXECUTION VENUE",
      executionVenues2019:
        "2019 TΟΠΟΙ ΕΚΤ. ΑΝΑ ΚΑΤΗΓ. ΧΡΗΜ. ΜΕΣΟΥ/2019 EXECUTION VENUE",
      executionVenues2018:
        "2018 TΟΠΟΙ ΕΚΤ. ΑΝΑ ΚΑΤΗΓ. ΧΡΗΜ. ΜΕΣΟΥ/2018 EXECUTION VENUE",
      executionVenues2017:
        "2017 TΟΠΟΙ ΕΚΤ. ΑΝΑ ΚΑΤΗΓ. ΧΡΗΜ. ΜΕΣΟΥ/2017 EXECUTION VENUE",
      generalMeetingInvite2013:
        "ΠΡΟΣΚΛΗΣΗ ΤΑΚΤΙΚΗΣ ΓΕΝΙΚΗΣ ΣΥΝΕΛΕΥΣΗΣ 28-06-2013",
    },
    accordion: "ΟΡΓΑΝΟΓΡΑΜΜΑ/COMPANY ORGANISATION CHART",
    officialOrganization: "AAAFx - Official Organizational Chart (HCMC)",
    certifiedEmploy: {
      title: "ΠΙΣΤΟΠΟΙΗΜΕΝΟΙ ΥΠΑΛΛΗΛΟΙ/CERTIFIED EMPLOYEES",
    },
    name: "NAME",
    cretificate: "CERTIFICATION",
    issuer: "ISSUER",
    year: "YEAR",
    table: {
      td: "DIMOPOULOS CHRISTOS",
      td1: "Provision of investment advice (B)",
      td2: "HCMC",
      td0: "DORDA AIKATERINI",
      td3: "Reception, transmission and execution of orders in transferable securities (A1)",
      td4: "KARLIS KONSTANTINOS",
      td5: "Portfolio management (C)",
      td6: "KONTOGEORGI ELENI",
      td7: "Reception, transmission and execution of orders in transferable securities (A1)",
      td8: "TZIANOUMI GIANNOULA",
      td9: " Provision of investment advice (B)",
      td10: "Securities Trader",
      td11: "ASE",
      td12: "POLYCHRONEAS EFSTRATIOS",
      td13: "Performing analyses on financial instruments or issuers (D)",
      tdRow1Content:'Provision of investment advice on securities (B1)',
      tdRow1Content1:'Reception, transmission and execution of orders in Derivatives (A2)',
      tdRow1Content2:'Reception, transmission and execution of orders in transferable securities (A1)',

    },
    maria: "CHRYSAITOU MARIA ARGYRO",
    investment:
      "Provision of investment advice (Β)",
    text: "PANAGIOTIS KOURBELIS",
    text1: "Trainee in the Trading Desk Department",
    text2: "GEORGIOS KARMIS",
    text3: "NIKOLAOS CHRISTODOULOU..........",

    responsive: {
      trTag: {
        line: "ΟΝΟΜΑΤΕΠΩΝΥΜΟ",
        line1: "ΠΙΣΤΟΠΟΙΗΣΗ",
        line2: "ΦΟΡΕΑΣ",
        line3: "ΕΤΟΣ ΚΤΗΣΗΣ/ΑΝΑΝΕΩΣΗΣ",
        line4: "ΔΗΜΟΠΟΥΛΟΣ ΧΡΗΣΤΟΣ ",
        line5: "Παροχή Επενδυτικών Συμβουλών (Β)",
        tdRow1Content:'Παροχή Επενδυτικών Συμβουλών σε Κινητές Αξίες (Β1)',
        tdRow1Content1:'Λήψη, Διαβίβαση και εκτέλεση Εντολών σε Παράγωγα Προϊόντα (Α2)',
        tdRow1Content2:'Λήψη, Διαβίβαση και εκτέλεση Εντολών σε Κινητές Αξίες (Α1)',
        line6: "Επιτροπή Επιτροπή",
        line7: "ΚΑΡΛΗΣ ΚΩΝΣΤΑΝΤΙΝΟΣ",
        line8: "Διαχείριση Χαρτοφυλακίων (Γ)",
        line9: "Επιτροπή Κεφαλαιαγοράς",
        line10: "ΚΟΝΤΟΓΕΩΡΓΗ ΕΛΕΝΗ",
        line11: "ΝΤΟΡΝΤΑ ΑΙΚΑΤΕΡΙΝΗ",
        line12: "Λήψη, Διαβίβαση και εκτέλεση Εντολών σε Κινητές Αξίες (Α1)",
        line13: "ΤΖΙΑΝΟΥΜΗ ΓΙΑΝΝΟΥΛΑ",
        line14: "Παροχή Επενδυτικών Συμβουλών (Β)",
        line15: "ΠΑΝΑΓΙΩΤΗΣ ΚΟΥΡΜΠΕΛΗΣ",
        line16: "Ασκούμενος Τμήματος Trading Desk",
        line17:
          "ΓΝΩΣΤΟΠΟΙΗΣΕΙΣ ΚΑΝΟΝΙΣΜΟΥ ΑΕΙΦΟΡΙΑΣ (SFDR)/SUSTAINABILITY REGULATION DISCLOSURES (SFDR)",
        line18: "Sustainability Regulation Disclosures (SFDR)",
        line19:
          "Γνωστοποιήσεις Κανονισμού Αειφορίας (SFDR) και Πολιτική Βιωσιμότητας (ESG)",
        line20: "Πιστοποιημένη Διαπραγματευτής Αξιών",
        line21: "Χρηματιστήριο Αξιών Αθηνών",
        line22: "ΧΡΥΣΑΪΤΟΥ ΜΑΡΙΑ-ΑΡΓΥΡΩ",
        line22Content: "Παροχή Επενδυτικών Συμβουλών (Β)",
        line23: "ΓΕΩΡΓΙΟΣ ΚΑΡΜΗΣ",
        line24: "ΝΙΚΟΛΑΟΣ ΧΡΙΣΤΟΔΟΥΛΟΥ.......",
        line25: "ΠΟΛΥΧΡΟΝΕΑΣ ΕΥΣΤΡΑΤΙΟΣ",
        line26: "Εκπόνηση Αναλύσεων σχετικά με Χρηματοπιστωτικά Μέσα ή Εκδότες (Δ)",
      },
    },
  },
  faq: {
    heading: "Frequently Asked Questions",
  },
  comparePlatform: {
    heading: "Compare Trading Platforms",
    content: "“The More The Merrier”",
    h6Tag:
      "AAAFx ensures to provide a one-stop and the best solution for every trader’s needs with the multiplicity of trading platforms offered.",
    pTag: "Every platform comes with the basic fundamentals of fastest execution speed, various customizable options, allowing the use of indicators and expert advisers, and the best available market prices.",
    condition: "Trading Conditions",
    timeFrames: "Timeframes",
    leverage: "Leverage",
    microLot: "Micro-lot trading",
    yes: "Yes",
    scalping: "Hedging and Scalping",
    attachment: "EAs/Indicators attachment",
    other: "Other Tools",
    economic: "Built-in Economic Calendar",
    no: "No",
    report: "Account Reports Format",
    html: "HTML",
    language: "Platforms language converter",
    priceAlert: "Price Alerts",
    device: "Device Operational Compatibility",
    window: "Windows Desktop",
    mac: "Mac/iPad",
    android: "Android",
    ios: "iOS",
    webBased: "Web-based",
    sutability: "Suitability",
    beginner: "Beginners",
    intermediate: "Intermediates",
    advance: "Advanced",
    openAccount: "Open Account",
    platform: "Which platform would you like for yourself?",
    choice:
      "That can be a difficult choice. Following is what you can consider:",
    point: "How easy is the User Interface for you?",
    point1: "Which platform suits your trading strategies?",
    point2: "Do you have support available for that platform?",
    point3:
      "For the last question, we have got you covered. We provide full technical support in case you face any issues with any platform.",
    point4: "For the other two questions, you can open a",
    point5: "Demo account",
    point6: "and get used to all nuances of our fantastic trading platforms!",
  },
  depWith: {
    heading: "Deposits and Withrawals",
    title:
      "A Variety of Easy and Convenient options, at absolutely no cost from our side",
    pTag: "Depositing and withdrawing from your trading account has never been so easy! Invest in your trading account by paying no extra fee. We have various reliable deposit and withdrawal options to make your trading journey smooth and easy.",
    serviceFee: "0% Service fee",
    charge: "We do not charge any fees on either deposits or withdrawals",
    safety: "Transactions Safety",
    funds:
      "The funds are kept in Tier-1 Banks and are segregated from the company’s own funds",
    quickProcessing: "Quick Processing",
    deposit: "Deposits and withdrawals with AAAFX are processed instantly",
    minDeposit: "Minimum deposit amount",
    globalCurrency: "Global cturrencies accepted",
    control: "Increased control",
    security: "Mandatory KYC ensures greater security",
    with: "WITHDRAWALS",
    seamless:
      "Seamless withdrawals make every trader happy, right? That is what we aspire to do for our clients. Here are some quick and easy options to use for the withdrawal of your hard-earned money within 12 hours.",
    step: "Steps to withdraw from your AAAFx Live Account:",
    step1: "1. Log into the Client Portal.",
    step2: "2. Set up a Bank account at ‘Bank setup’.",
    step3: "3. Click on “Funds” and then “Withdraw”.",
    depositText: "Deposit",
    withdrawal: "Withdrawals",
    bankCard: "Bank Cards",
    region: "Region",
    currencies: "Currencies",
    min: "Min. Deposit",
    service: "Service Fee",
    processing: "Processing Time",
    worldWide: "World Wide",
    instant: "INSTANT",
    fund: "FUND",
    bankWire: "Bank Wire",
    days: "2-3 BUSINESS DAYS",
    europe: "Europe",
    southEast: "South East Asia",
    latinAmerica: "Latin America",
    mexico: "Mexico",
    eastAfrica: "East Africa",
    global: "Global",
    info: "Additional Information:",
    step4:
      "1.	All withdrawals will be processed via the method which was used to make a deposit in the trading account.",
    step5:
      "2.	No transaction fee is charged by AAAFx either on deposits or on withdrawals.",
    step6:
      "3.	We reimburse the transaction cost incurred by you up to 5% of your deposit amount, into your trading account.",
    live: "OPEN LIVE ACCOUNT",
    demo: "OPEN DEMO ACCOUNT",
  },
  whiteLabels: {
    banner: {
      menu: {
        white_label: "White Label",
      },
      title: "White Label",
      subtitle: "Our experience, Your success!",
    },
    pamm_content: {
      intro: {
        heading:
          "A unique opportunity to set up your own brokerage, with the help of our knowledge, experience, and support.",
      },
      description: {
        paragraph_1:
          "A white-label program is a great way to set up a strong position quickly in market. This is possible with the use of our technology, impeccable trading conditions, support services and useful back-office CRM tools.",
        paragraph_2:
          "The AAAFx team is dedicated to your success through our technical and professional support.",
        paragraph_3:
          "Our white label program offers you full flexibility to design your products and services, so that you can get the best opportunity to flourish your venture.",
        paragraph_4:
          "Choosing the right broker is the first step in accomplishing your desired goals.",
        paragraph_5: "Gear up and make the right choice now!",
      },
    },
    expectations_section: {
      heading: "What to expect?",
      list: {
        item_1: "Collaboration with Largest Social Trading Network",
        item_2: "Dedicated Account Managers for Investors and Managers",
        item_3:
          "Flexibility in setting parameters and conditions for the account",
        item_4: "Ultra-low commissions with raw spreads",
        item_5: "Security of funds with a trusted broker",
        item_6: "Small investment required to start",
        item_7: "Transparency for the investors- no hidden clauses",
        item_8: "Full control to investors related to deposits and withdrawals",
      },
    },
    contact_section: {
      title: "Ready to receive customized solutions for your new endeavor?",
      contact_us: "Contact Us now",
      email_link: "here",
      email_info: "or write to us at",
      email_address: "support{'@'}aaafx.com",
    },
  },
  vpsHosting: {
    banner: {
      title: "Free VPS Hosting",
    },
    bonus_trade_section: {
      description:
        "Trade from anywhere in the world conveniently without connection interruptions and save yourself from worrying about internet or power outages. It is vital to minimize any latency while trading because a minor delay can cost you a fortune.",
      customer_approach:
        "In line with our customer centric approach, we always want our clients to have the best trading experience possible. AAAFx brings Virtual Private Server Services, at absolutely no additional cost to you.",
      advantages_title: "Advantages of Trading using VPS",
      advantages_list: {
        item_1:
          "VPS allows your trades to be executed at any time with minimum delay which results in less slippage.",
        item_2:
          "VPS provides endless operation of the trading platform and makes it more suitable if you use trading robots or trading signals.",
        item_3:
          "It allows you to trade from any place in the world even if proper internet connection is not available, the power runs out or system crashes.",
        item_4:
          "VPS offers anonymity, privacy and security which allows you to trade with peace of mind, knowing your essential data is safe.",
      },
    },
    accordion_section: {
      how_to_get_vps: {
        title: "How can I get a free VPS?",
        content:
          "If you are a client with slightly high volumes in trading (with a deposit of at least $5,000), you are eligible for a free VPS.",
      },
      why_free_vps: {
        title: "Why does AAAFX give a free VPS?",
        content:
          "Our technology partner provides us low-cost VPN hosting, in return of high volumes. We like to pass on the benefits that we earn to our clients.",
      },
      lesser_deposit: {
        title: "What if my deposit is lesser?",
        content:
          "That is no problem. You can still avail our VPS at a very low rental of only $25 per month. This rental will be deducted automatically from your trading account.",
      },
      other_vps: {
        title:
          "What if I want to use some other VPS instead of the AAAFx facilitated VPS?",
        content:
          "If you want to use your own VPS or from a third party, we can give you a reimbursement for your VPS up to $25/month, provided your overall net deposit for the month is at least $5,000. Just share with us your VPS purchase details and monthly payment receipt in order to avail this reimbursement.",
      },
    },
    vps_configuration_section: {
      title: "Free VPS Configuration",
      configuration: {
        intel: {
          title: "INTEL",
          description: "Processor",
        },
        ram: {
          title: "1300 MB",
          description: "RAM",
        },
        bandwidth: {
          title: "25 GB",
          description: "Bandwidth",
        },
        disk_space: {
          title: "2 TB",
          description: "Disk Space",
        },
      },
    },
    apply_vps_section: {
      title: "How to apply for free VPS?",
      steps: {
        step_1: {
          description: "Open a Live Account",
        },
        step_2: {
          description: "Deposit minimum $5,000 or equivalent currency",
        },
        step_3: {
          description: "Email us at",
          email: "support{'@'}aaafx.com",
        },
      },
    },
  },
  tradingCondition: {
    heading: "Trading Conditions",
    pTag: "Features that will make you trade more profitably! A trading environment best suited for all kinds of trading strategies.",
    h5Tag:
      "Take a look at all the terms and conditions to plan for an impeccable trading experience:",
    content:
      "AAAFx presents trading terms and conditions suitable for every trader - Be it a beginner or an expert. We want our clients to trade with utmost confidence and comfort.",
    deepLiquidity: "ECN Trading with Deep liquidity",
    buySell:
      "ECN (Electronic Communication Network) accounts are designed to match buy and sell orders through direct access to liquidity providers.",
    brTag:
      "Through our Prime LPs, we are able to pass on the best prevailing market prices and liquidity to all our clients in a seamless way. Hence you get access to the deep liquidity pools instantly at a negligible spread cost.",
    powerfulArsenal: "AAAFx Powerful Trading Arsenal",
    arsenalContent:
      "We know how crucial it is to choose a quintessential trading platform to implement your trading strategy! We have taken care of all your requirements to trade swiftly with easy navigation and user interface and earn from over 200+ trading instruments available on three full-packed trading platforms - MT4, MT5, ActTrader. The icing on the cake is connection to Zulu Trade via Zulu Account, that will help you get instantly connected to the largest social trading community.",
    zeroCommission: "Zero commission and Razor thin Spreads",
    commissionContent:
      "We provide you the best conditions to trade at the lowest cost. With our ECN plus account, you can trade free of commission charges on all Forex pairs. With ultra-thin spreads starting from 0.0 pips on all our tradable instruments, you can start using our institutional grade liquidity at a super-low cost. We get these trading conditions from our top-tier liquidity providers to serve you the best in your trading journey.",
    fastExecution: "Lightning-fast execution",
    executionContent:
      "The top-notch quality and location of our severs enables the fastest trade execution possible at a speed as fast as 10ms. You can place trade orders of upto 100 lots. You are free from delays, requotes and dealing desks. This makes our platform ideal for all kinds of traders - scalpers, beginners, EA users, manual traders, etc.",
    fundingOptions: "Variety of Funding Options",
    fundingContent:
      "Seamless deposits and withdrawals go a long way in making your trading journey easy and smooth. You can choose your deposit method from diverse options available. We know every penny counts in funding your trading accounts. Thus, we do not charge any fees while accepting deposits or processing withdrawals. Withdrawals are processed ultra-fast in a seamless manner, and you get a variety of withdrawal modes to choose from.",
    lowRollover: "Low Rollover charges",
    rolloverContent:
      "Several traders like to hold their trades overnight and for that purpose, swap rates matter. Each trading pair has its own swap charges (except on Islamic accounts). These rates are applied at 00:00 Server time. The markets are closed over the weekend; however, Banks still calculate interest even on positions held over the weekend. So, if you hold a position overnight on a Friday, you will incur 3X swap rates. We offer competitive and low swap charges so that you have more flexibility to decide when you want to close your trades.",
    doubt: "Have doubts over any trading condition? Try our",
    demoAccount: "Demo account",
    stayAware: "Stay aware and make use of extensive",
    calculate: "Trading Calculators",
    getAll: "to get all the numbers right!",
    liveAccount: "OPEN LIVE ACCOUNT",
    openAccount: "OPEN DEMO ACCOUNT",
  },
  tradingCalculator: {
    title: "Trading Calculators",
    madeEasy: "Calculations made easy for you",
    neverMiss: "Never miss out on trading profits due to bad calculations!",
    pTag: "Make accurate assessments in blink of an eye by using our Trading Calculators. We are committed to help you make the most out of your trades by saving time and focusing all the attention on crucial trading decisions instead of engaging in tedious calculations. We have handpicked the most useful calculators for trading and included them here for you. You do not have to download anything. Just choose the calculator you want, enter your inputs, and the calculator will give you the answer!",
    swap: "Swap Calculator",
    margin: "Margin Calculator",
    pip: "Pip Calculator",
    calculate: "Calculator",
  },
  forexStock: {
    innerPages: {
      forex: "Forex",
      indices: "Indices",
      commodities: "Commodities",
      stocks: "Stocks",
      crypto: "Cryptocurrencies",
    },
    title: "FOREX",
    trade: "Trade in prominent forex pairs with AAAFx",
    min: "min.",
    commission: "Commission",
    execution: "execution",
    spread: "Spread",
    upTo: "Up to ",
    leverage: "Leverage",
    paris: "Pairs",
    contract: "Forex Contract",
    specification: "Specifications",
    flexible:
      "More flexible trading conditions (increased leverage) may apply in case of clients classified as Professional. Please log in to your account and check eligibility for Professional Client, classification, or write to us at",
    email: "support{'@'}aaafx.com",
    instument: "Instrument",
    bid: "Bid",
    ask: "Ask",
    size: "Contract Size",
    minTrade: "Min. Trade Size",
    tickValue: "Tick Value",
    hours: "Trading Hours",
    h3Tag: "What is Forex Trading?",
    pTag: "The Forex market is an expansive global market for exchanging the international currencies. Forex trading is the general buying and selling of currencies with the main intent of earning profit. The Global forex market is the largest and the most liquid market in the world, with a daily transactional volume of more than $6 trillion.",
    how: "How does it work?",
    trading:
      "Forex trading involves buying one currency and selling it for another currency on the prevailing market exchange rate. There is no delivery of any currency and the difference between selling price and buying price is simply adjusted in the trading account. Hence, these forex pairs are like Forex CFDs at Spot prices.If you want to trade in the core forex market, you will need to shell out hundreds of thousand dollars. However, with the benefit of leverage trading and CFDs, you only need as little as 100 units of a currency, and you can start taking benefit of movements in prices. Further, you can also benefit from the interest rate differential between two currencies, via swap rates. If you keep an open position in a currency pair overnight, and the interest on the currency you have bought is higher than that on the currency you have sold, you will earn some interest income. Else, you will pay some charge. This interest is known as swap or rollover rate.",
    account: "Account",
    type: "Types",
    compare: "Compare Account Types",
    online: "Our Online",
    tradingText: "Trading",
    platform: "Platforms",
    desktop: "Desktop",
    webTrade: "Web Trader",
    android: "Android",
    ios: "iOS",
    learn: "LEARN MORE",
    negative: "Negative Balance",
    protection: "Protection",
    pSubTag: "Reliable protection of your funds on AAAFx Accounts",
    pTag1:
      "We always endeavor to work towards the best interest of our clients. While trading in volatile times, if your account goes into negative, we have got your back! We will reset your negative balance to zero, so that you do not bear any extra loss. You can deposit and start trading again. Hence, you cannot lose more than what you deposit.",
    live: "OPEN LIVE ACCOUNT",
    demo: "OPEN DEMO ACCOUNT",
  },
  pageNotFound: {
    title:
      "The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.",
    redirect: "Go to AAAFx Homepage",
  },
  annualLoser: {
    winner: "Winner",
    loser: "Losers",
    total: "Total",
    percentage: "Percentage of losers",
    annually: "Annually",
    semi_annually: "Semi-Annually",
    quaterly: "Quarterly",
    monthly: "Monthly",
  },
  safetyFund: {
    title: "Safety of Funds",
    heading: "Integrity, Trust and Full Reliability",
    safety: "Funds Safety and Security",
    investor:
      "Investors should focus all their time and energy on building effective strategies, rather than worrying about their funds’ safety.",
    protect:
      "We are here to protect your funds because our foundation lays on your trust. We want you to invest smartly and trade safely.",
    heading1: "REGULATED & GUARDED",
    strict:
      "In Compliance with strict regulatory framework of EU’s Hellenic Capital Market Commission (HCMC)",
    responsible: "RESPONSIBLE FIDUCIARY",
    client:
      "Client’s money is safely held in Secure Bank Accounts, with trustworthy banks.",
    audit: "DEDICATED AUDITS AND",
    review: "COMPLIANCE REVIEWS",
    internal:
      "Internal and External audits, Dedicated Compliance function to constantly review the application of client asset procedures.",
    negativeBalance: "NEGATIVE BALANCE PROTECTION",
    pTag: "The clients cannot lose more than their account balance. If at all their balance goes negative, we reset it to zero",
    funds: "SEGREGATED FUNDS AND RECORDS",
    companyFunds:
      "Strict segregation between clients and company funds. A clear distinction is maintained between client funds/records and those of the company",
    policies: "ROBUST IT POLICIES",
    secure:
      "Secure web sockets on the website, Cloudflare technology, and Two Factor Authentication (2FA) available for all client accounts.",
    investorFund: "INVESTORS COMPENSATION FUND",
    perPerson:
      "AAAFx investors are protected up to €30,000 per person by the Greek Guarantee Fund according under the European Directive 93/22/EEC.",
    security: "We are always committed towards ensuring your security.",
    awareness: "But your Awareness and Alertness is equally important:",
    never: "Never",
    click: "click on unidentified links in emails.",
    unSecured:
      "use unsecured wireless networks or any public device for accessing financial accounts.",
    otps: "reply to emails or SMSs seeking personal information or OTPs.",
    websites:
      "enter your personal or financial information on unsecured websites.",
    leave:
      "leave your device unattended while you are logged in to secured personal or financial accounts.",
    twoFa: "operate a financial account without 2FA.",
  },
  regulations: {
    title: "Regulations and Licenses",
    multiple:
      "We have multiple registrations and regulations around the globe. Full transparency about our registration details means more reliability, security, and peace of mind for our clients!",
    capitalMarket: "Hellenic Capital Market Commission (HCMC), Greece",
    member:
      "Triple A Experts Investment Services Single Member SA is authorized and regulated in European Union by the Hellenic Capital Market Commission. (id:2/540/17.2.2010) Tax Identification Number: 998281207.",
    h5Tag: "About HCMC",
    pTag: "The Hellenic Capital Market Commission (HCMC) was established as a legal entity in Greece for surveillance of the proper application of capital market legislation. The HCMC is a Member of the European Securities and Markets Authority (ESMA) and International Organization of Securities Commissions (IOSCO).",
    about: "About MiFID II",
    adopted:
      "The European Parliament and Council adopted Directive 2014/65/EU, dated 15 May 2014, on Markets in Financial Instruments and Amending Directive 2002/92/EC and Directive 2011/61/EU (recast) (MiFID II), which came into effect on 03/01/2018. Its aim is to regulate the markets of financial instruments for the provision of investment services and activities within the European Economic Area (EEA). MiFID was incorporated to the Greek legislation Under Law No 4514/2018. The main objectives of the Directive are to increase efficiency, enhance financial transparency, increase competition, and offer greater consumer protection in investment services.",
    gdpr: "About GDPR",
    aboutGDPR:
      "The General Data Protection Regulation (GDPR) is considered to be one of the toughest privacy laws in the world. It was drafted and passed by the European Union (EU) and put into effect on May 25, 2018. It lays down a legal framework that sets guidelines for the collection and free movement of personal data of individuals within the European Union (EU). “AAAFx” and its affiliated companies, including its subsidiaries, branches, representative offices, directors, officers and employee take every reasonable step to comply with GDPR.",
    investor: "About Investor Compensation Fund",
    hcmc: "AAAFx investors are protected by the Greek Guarantee Fund according to the European Directive 93/22/EEC. In the event of an Investment Firm’s failure to fulfil its obligations, the Greek Guarantee Fund will act in order to compensate the investors up to €30,000 per person. The Greek Guarantee Fund is financed by the ATHEX (Athens Exchange) Members, and it is supervised by the HCMC.",
    group: "Group Regulations",
    sector: "Financial Sector Conduct Authority (FSCA), South Africa",
    southAfrica:
      "Sikhula Venture Capital (Pty) Ltd. is authorized and regulated in South African Financial Sector Conduct Authority (FSCA). (Licence Number: 2017/315029/07).",
    view: "View License",
    register: "Registrations in other Jurisdictions",
  },
  homePage: {
    h3tag: "The Future of FOREX is...",
    h1tag: "ULTRA-LOW commissions!",
    ul: {
      lTag: "Raw spreads",
      strong: "from",
      strong1: "0.0",
      lTag1: "pips",
      lTag2: "Leverage up to",
      strong2: "500x*",
      supTag: "professional customers",
      // bTag: "Greek",
      lTag3: "and EU Regulations and Registrations",
      // strong3: "Free",
      lTag4: "Deposits and Withdrawals*",
    },
    liveAccount: "OPEN LIVE ACCOUNT",
    demoAccount: "OPEN DEMO ACCOUNT",
    tabs: {
      forex: "Forex",
      indices: "Indices",
      commodities: "Commodities",
      stocks: "Stocks",
      cryptoCurrencies: "Cryptocurrencies",
    },
    forex: {
      title: "70+ FX Pairs",
      point1: "1",
      content: "World’s most liquid market",
      pTag: "Enjoy access to the world’s most liquid markets",
      point2: "2",
      h5Tag: "Trade micro lot sizes",
      pTag1: "Trade as per any strategy, micro lots up to 100 lots",
      point3: "3",
      h5Tag1: "Advanced charting indicators",
      pTag2: "Use our splendid trading platforms with advanced tools",
      startTrading: "START TRADING",
      tryDemo: "TRY A DEMO",
      tableRow: {
        tableHeading: {
          pair: "Pair",
          bid: "Bid",
          ask: "Ask",
          spread: "Spreads",
          trade: "Trade",
        },
      },
      buySell: "BUY/SELL",
      SeeAll: "See All",
    },
    indices: {
      title: "11+ Indices",
      point1: "1",
      content: "Trade ever growing Indices",
      pTag: "such as DOW JONES, S&P 500, Nasdaq and many more…",
      point2: "2",
      h5Tag: "Low Spreads and low margin requirements",
      pTag1: "We have thinnest spreads, high leverage on all Global Indices",
      point3: "3",
      h5Tag1: "Diversify- trade a portfolio, not a single stock",
      pTag2: "We offer all major Global Indices for trading",
      startTrading: "START TRADING",
      tryDemo: "TRY A DEMO",
      tableRow: {
        tableHeading: {
          pair: "Pair",
          bid: "Bid",
          ask: "Ask",
          spread: "Spreads",
          trade: "Trade",
        },
      },
      buySell: "BUY/SELL",
      SeeAll: "See All",
    },
    commodities: {
      title: "9 Commodities",
      point1: "1",
      content: "Broad range of commodities",
      pTag: "All major precious metals, Oil and Natural Gas available",
      point2: "2",
      h5Tag: "Invest less, get more",
      pTag1: "Get hold of precious assets easily with leverage",
      point3: "3",
      h5Tag1: "Zero latency, Minimal slippage",
      pTag2: "Minimal impact of volatility with execution speed 10 ms.",
      startTrading: "START TRADING",
      tryDemo: "TRY A DEMO",
      tableRow: {
        tableHeading: {
          pair: "Pair",
          bid: "Bid",
          ask: "Ask",
          spread: "Spreads",
          trade: "Trade",
        },
      },
      buySell: "BUY/SELL",
      SeeAll: "See All",
    },
    stocks: {
      title: "100+ Stocks",
      point1: "1",
      content: "Trade Blue Chip Stocks",
      pTag: "Drag trusted blue-chip stocks into your portfolio",
      point2: "2",
      h5Tag: "Earn dividends without owning stocks",
      pTag1: "Earn exciting dividend income from top notch companies",
      point3: "3",
      h5Tag1: "Go long or Go short, as per Market",
      pTag2:
        "Trade not only rising but falling stocks, using any trading strategy",
      startTrading: "START TRADING",
      tryDemo: "TRY A DEMO",
      tableRow: {
        tableHeading: {
          pair: "Pair",
          bid: "Bid",
          ask: "Ask",
          spread: "Spreads",
          trade: "Trade",
        },
      },
      buySell: "BUY/SELL",
      SeeAll: "See All",
    },
    crypto: {
      title: "6 Cryptocurrencies",
      point1: "1",
      content: "Trade Money spinning Cryptos",
      pTag: "Trade the most lucrative assets of today’s age",
      point2: "2",
      h5Tag: "Most powerful cryptos right at your trading platform",
      pTag1: "All major cryptocurrencies available to trade",
      point3: "3",
      h5Tag1: "Reliable trading for volatile assets",
      pTag2: "Have access to deep liquidity with a Regulated, reliable broker",
      startTrading: "START TRADING",
      tryDemo: "TRY A DEMO",
      tableRow: {
        tableHeading: {
          pair: "Pair",
          bid: "Bid",
          ask: "Ask",
          spread: "Spreads",
          trade: "Trade",
        },
      },
      buySell: "BUY/SELL",
      SeeAll: "See All",
    },
    youShouldJoin: "Why You Should Join",
    aaafx: "AAAFx",
    content: {
      h3Tag: "Top-notch Trading conditions",
      ul: {
        liTag: "Fastest trade execution with near zero spreads",
        liTag1: "Low commission and rollover charges",
        liTag2: "All trading strategies allowed",
        liTag3: "No hidden markups",
      },
    },
    content1: {
      h3Tag: "Security and Reliability",
      ul: {
        liTag: "EU Regulated Broker.",
        liTag1: "Registration Number: 2/540/17.2.2010.",
        liTag2: "Negative Balance Protection.",
        liTag3: "Strict Adherence to Fiduciary Compliance.",
      },
    },
    content2: {
      h3Tag: "14 years’ Industry Experience",
      ul: {
        liTag: "In business from over a decade",
        liTag1: "Team of able and seasoned professionals",
        liTag2: "Trusted and Transparent Broker",
        liTag3: "Deep understanding of traders’ needs",
      },
    },
    content3: {
      h3Tag: "Deep Liquidity Pool",
      ul: {
        liTag: "Institutional grade liquidity",
        liTag1: "No requotes and minimal slippage",
        liTag2: "Guaranteed liquidity at all price levels",
        liTag3: "Suitable for high volume trading",
      },
    },
    content4: {
      h3Tag: "Technology",
      ul: {
        liTag: "High-end in-house technology",
        liTag1: "Servers in top-notch Equinix data centres",
        liTag2: "Fastest execution speed under 10 ms",
        liTag3: "Free collocated VPS providing even lower latency",
      },
    },
    content5: {
      h3Tag: "Ultra-Fast Withdrawals",
      ul: {
        liTag: "Free withdrawals within 24 hours",
        liTag1: "No restrictions on amount or frequency",
        liTag2: "Several flexible withdrawal options",
        liTag3: "High Security withdrawals processing",
      },
    },
    rawSpread: "Raw Spreads, Zero Commission",
    plusAccount: "on FX pairs with an ECN plus account",
    pTag: " AAAFx is here to reduce the tension and commissions from your trading journey. Yes, you read that right! We have room for you to maneuver a low-cost trading experience with us. For more information",
    clickHere: "click here",
    brTag:
      "We give you full potential to achieve the best possible result. With the use of state-of-the-art technology, we have empowered ourselves to get you instant access to deep liquidity pool at low-cost.",
    ecn: "ECN",
    commission: "Commission",
    minSpread: "min. Spread",
    minDeposit: "min. Deposit",
    plus: "plus",
    forexCommission: "Commission on Forex",
    pips: "pips",
    compare: "Compare Account Types",
    experience:
      "Experienced, Trusted and Regulated with a Promise of Protection of your funds",
    security: "Security is not a product, but a process.",
    strongValue:
      "With a strong value system, we deliver a world class trading experience. We comply with a strict Regulatory framework in order to protect your funds. We are regulated by the Hellenic Capital Market Commission (HCMC), that is governed by Greek and European legislations.",
    ourClient:
      "Our clients rely on us as we take all measures to ensure safety of their funds and seamless execution of their trades.",
    success: "Trade with peace, trade for success!",
    live: "OPEN LIVE ACCOUNT",
    demo: "OPEN DEMO ACCOUNT",
    zuluTrade: "ZuluTrade",
    treasure: "A Treasure House For Forex Traders!",
    copyTrade: "Copy a trading master or become one!",
    socialTrading:
      "Access the largest social trading network through a reliable broker, with our Zulu Account. It lets you automatically copy the trades of some successful traders who showcase their live trading performance.",
    investor:
      "of their investors make profits when they copy top traders correctly.",
    topTrader: "Become a top trader or follow a top trader.",
    yourTrading: "Your Trading",
    arsenal: "Arsenal",
    actTrader: "Act Trader",
    mostAdvance: "Most Advanced Technology",
    comming: "Coming Soon",
    desktop: "Desktop",
    ios: "iOS",
    android: "Android",
    web: "Web",
    mt4: "MetaTrader 4",
    popularPlatform: "Most Popular Platform",
    mt5: "MetaTrader 5",
    nextGen: "The next-gen platform",
    firstTrade: "Place Your First Trade with AAAFx in less than 15 minutes",
    register: "Register",
    min: "Within a min",
    kyc: "KYC",
    photo: "A Valid Photo ID",
    addressProof: "A Recent Proof of Address",
    // withIn: "Within 10 minutes",
    deposit: "Deposit",
    instantly: "Instantly",
    method: "Over 5 deposit methods",
    trade: "Trade",
    range: "A range of Markets",
    spread: "Raw spreads",
    ultraLow: "Ultra-low-commission",
    create: "CREATE YOUR ACCOUNT",
  },
  contactUsPage: {
    heading: "Contact Us",
    headingDescription: "Our Support Team is here for you 24/5",
    requestCallBack: "Request a Call Back:",
    FullName: "Full Name",
    EmailAddress: "Email Address",
    PhoneNumber: "Phone Number",
    Are_you_an_existing_client: "Are you an existing client?",
    Yes: "Yes",
    No: "No",
    Select_your_Timezone: "Select your Timezone",
    How_can_we_help_you: "How can we help you?",
    Select_a_topic: "Select a topic",
    NewAccounts: "New Accounts",
    CustomerSupport: "Customer Support",
    TechnicalQueries: "Technical Queries",
    IBProgram: "IB Program",
    PAMM_Accounts: "PAMM Accounts",
    WhiteLabel: "White Label",
    BestTimeToCallMessage:
      "When is the best time to call you? Please selecthere",
    SelectPreferredTime: "Select Preferred Time",
    eightAM: "8:00",
    tenAM: "10:00",
    twelveNoon: "12:00",
    twoPM: "14:00",
    fourPM: "16:00",
    sixPM: "18:00",
    eightPM: "20:00",
    Message: "Message",
    I_agree_to_AAAFx: "I agree to AAAFx's",
    PrivacyPolicy: "Privacy Policy",
    LiveChat: "Live Chat",
    AvailableMondayToFriday: "(24/5, Monday-Friday)",
    CallUs: "Call Us",
    GreeceAndAthens: "Greece, Athens",
    GreeceAndAthensContactNumber: "+30 213 0176300",
    AvailableMondayToFridayTime: "Monday to Friday 9:00-18:00",
    EmailAddresses: "Email Addresses",
    Support: "SUPPORT",
    Complaints: "COMPLAINTS",
    FollowUs: "Follow Us",
    RegisteredAddress: "Registered Address",
    ProvidedRegisteredAddressOne:
      "Triple A Experts Investment Services Single Member S.A",
    ProvidedRegisteredAddressTwo: "580 Vouliagmenis Avenue 16452",
    ProvidedRegisteredAddressThree: "Argyroupoli Attica, Greece",
  },
  depositBonusPage: {
    heading: "Deposit Bonus",
    headingDescription: "Why settle for less?",
    subHeadingOne: "With AAAFX Bonus, trade double with the same deposit",
    subHeadingOneDescription: `We are currently providing a promotional offer to avail free credit (aka bonus money) in the same amount that you make your deposit. This Bonus money instantly doubles-up your margin so that you can trade more and potentially earn more.`,
    What_is_Bonus: "What is Bonus?",
    What_is_Bonus_desc: `Bonus gets added as an extra margin to your trading account. It is straight away added as a Credit to your trading account, and it increases your trading account’s total equity.`,
    Why_should_I_avail_Bonus: "Why should I avail Bonus?",
    Why_should_I_avail_Bonus_pointOne:
      "Double trading margin available at no extra cost",
    Why_should_I_avail_Bonus_pointOne_desc: `Let’s say you deposit $1,000, we will add another $1,000 to your account. Hence, you will get a total equity of $2,000 to trade with. You can avail the free bonus money instantaneously, once you have made the deposit.`,
    Why_should_I_avail_Bonus_pointTwo:
      "Instant addition of extra funds in account",
    Why_should_I_avail_Bonus_pointTwo_desc: `For availing bonus, you have to simply log into your secure Client Area after making the deposit and click on ‘Avail Bonus’. It gets added instantly to your trading account.`,
    Why_should_I_avail_Bonus_pointThree:
      "PROFITS EARNED FROM BONUS ARE ALL YOURS",
    Why_should_I_avail_Bonus_pointThree_desc: `You do not owe us anything from the profits you earn either from trading on your own deposit or the bonus added by us. You can take out all profits earned by you in your account as and when you want.`,
    Why_should_I_avail_Bonus_pointFour: "Delayed Stop-out Level",
    Why_should_I_avail_Bonus_pointFour_desc:
      "Another advantage of using the Bonus Money is that in case of an unfortunate draw drown, your account’s Stop-out gets delayed. Your trades will not be force closed by the system till ‘ALL’ of your own funds are lost in trading. The regular stop-out level is at 50%, i.e. when your ‘Equity’ to ‘Used Margin’ ratio reaches at 50%, your account gets stopped out. This extra cushion gives an additional opportunity for the trades to go into the opposite direction and potentially turn profitable.",
    Why_should_I_avail_Bonus_pointFive:
      "We have your back for any negative Balance",
    Why_should_I_avail_Bonus_pointFive_desc: `If by chance you happen to lose your own funds and your balance goes negative, we provide full protection to you. You cannot lose more than your what you deposit. We reset your negative account balance to zero.`,
    Why_should_I_avail_Bonus_pointSix:
      "Can my losses be adjusted against bonus?",
    Why_should_I_avail_Bonus_pointSix_desc: `Losses cannot be dipped into bonus. Once all of your own funds are lost in trading, any open trades will be closed at the prevailing market prices.`,
    ReadAllTermsCondition: "Read all Bonus Terms and Conditions",
    here: "here",
    ClaimBonus: "CLAIM BONUS",
    ClaimBonusQuestion: `Questions? Reach us at Live Chat or Write to us at`,
  },
  depositWithdrawalPage: {
    heading: "Deposits and Withdrawals",
    headingDescription:
      "A Variety of Easy and Convenient options, at absolutely no cost from our side",
    subHeading: `Depositing and withdrawing from your trading account has never been so easy! Invest in your trading account by paying no extra fee. We have various reliable deposit and withdrawal options to make your trading journey smooth and easy.`,
    subHeadingPointOne: "0% Service fee",
    subHeadingPointOneDesc:
      "We do not charge any fees on either deposits or withdrawals",
    subHeadingPointTwo: "Transactions Safety",
    subHeadingPointTwoDesc:
      "The funds are kept in Tier-1 Banks and are segregated from the company’s own funds",
    subHeadingPointThree: "Quick Processing",
    subHeadingPointThreeDesc: `Deposits and withdrawals with AAAFx are processed instantly`,
    subHeadingPointFour: "$100",
    subHeadingPointFourDesc: `Minimum deposit amount`,
    subHeadingPointFive: "10+",
    subHeadingPointFiveDesc: `Global currencies accepted`,
    subHeadingPointSix: "Increased control",
    subHeadingPointSixDesc: `Mandatory KYC ensures greater security`,
    WithdrawalUpperCase: "WITHDRAWALS",
    WithdrawalDesc: `Seamless withdrawals make every trader happy, right? That is what we aspire to do for our clients. Here are some quick and easy options to use for the withdrawal of your hard-earned money within 24 hours.`,
    StepsToWithdrawal: `Steps to withdraw from your AAAFx Live Account`,
    StepsToWithdrawalPointOne: "Log into the Client Portal.",
    StepsToWithdrawalPointTwo: "Set up a Bank account at ‘Bank setup’.",
    StepsToWithdrawalPointThree: "Click on “Funds” and then “Withdraw”.",
    Deposit: "Deposit",
    Withdrawals: "Withdrawals",
    BankCards: "Bank Cards",
    Region: "Region",
    Currencies: "Currencies",
    MinDeposit: "Min. Deposit",
    ServiceFee: "Service Fee",
    ProcessingTime: "Processing Time",
    WorldWide: "World Wide",
    EUR: "EUR",
    USD: "USD",
    CHF: "CHF",
    GBP: "GBP",
    JPY: "JPY",
    AUD: "AUD",
    BGN: "BGN",
    zero: "0",
    ten: "10",
    instantCaps: "INSTANT",
    fundCaps: "FUND",
    BankWire: "Bank Wire",
    twoToThreeBusinessDays: "2-3 BUSINESS DAYS",
    MY: "MY",
    TH: "TH",
    ID: "ID",
    PH: "PH",
    VN: "VN",
    BTC: "BTC",
    AdditionalInformation: "Additional Information",
    AdditionalInformationPointOne:
      "All withdrawals will be processed via the method which was used to make a deposit in the trading account.",
    AdditionalInformationPointTwo:
      "No transaction fee is charged by AAAFx either on deposits or on withdrawals.",
    AdditionalInformationPointThree:
      "We reimburse the transaction cost incurred by you up to 5% of your deposit amount, into your trading account.",
    CapsOpenLiveAccount: "OPEN LIVE ACCOUNT",
    CapsOpenDemoAccount: "OPEN DEMO ACCOUNT",
    DepositUpperCase: "DEPOSITS",
    DepositDesc:
      "What is more important than adding funds securely to your trading account? Start your trading journey with a minimum deposit of just $10. We offer a wide variety of options that you can use to fund your trading account.",
    Steps_to_make_a_deposit: `Steps to make a deposit in your AAAFx Live Account`,
    Steps_to_make_a_deposit_pointOne: "Log into the Client Portal.",
    Steps_to_make_a_deposit_pointTwo: "Click on “Funds” and then “Deposit”.",
    Bitcoin: "Bitcoin",
    uptoThirtyMin: "Upto 30 min",
    e_wallets: "E-Wallets",
    depositNoteOne:
      "In case of network congestion, Bitcoin transactions may take several hours.",
    depositNoteTwo:
      "For bank wire deposits, AAAFx will cover only the fees applied by our recipient bank i.e Eurobank and up to the amount of $25. AAAFx is not aware of any other fee that may be applied by the sender or any other intermediary bank and thus these fees will not be covered.",
    EuroBankCaps: "EUROBANK",
    Currency: "Currency",
    SwiftCode: "Swift Code",
    BeneficiaryName: `Beneficiary's Name`,
    ProvidedBeneficiaryName: "TRIPLE A EXPERTS",
    ProvidedBeneficiaryNameTwo: "TRIPLE A EXPERTS INVESTMENT SERVICES SA",
    IBAN: "IBAN",
    Address: "Address",
    ProvidedAddress: "Akti Kondili 26-28, 18545 Piraeus, GREECE",
    ProvidedAddressTwo: "Chemin de la Crétaux 33, 1196 Gland, Switzerland",
    ProvidedSwiftCode: "ERBKGRAA",
    ProvidedSwiftCodeTwo: "SWQBCHZZXXX",
    IBANCodeOne: "GR9502600590000421200023114",
    IBANCodeTwo: "GR3202600590000410200460346",
    IBANCodeThree: "GR9702600590000441200009691",
    IBANCodeFour: "GR4202600590000451200009759",
    IBANCodeFive: "GR0602600590000411200012446",
    IBANCodeSix: "GR6202600590000441200012358",
    IBANCodeSeven: "GR5402600590000471200017496",
    IBANCodeEight: "CH0708781000097621100",
    Warning: "Warning",
    WarningMessage:
      "Include always your email, full name and trading account number (just email and full name for new accounts) in the outgoing wire details for immediate processing. Otherwise AAAFx may delay your deposit.",
    swissubebankSA: "SWISSYUBE BANK SA",
  },
  stocksPage: {
    Forex: "Forex",
    Indices: "Indices",
    Commodities: "Commodities",
    Stocks: "Stocks",
    Cryptocurrencies: "Cryptocurrencies",
    heading: "STOCKS",
    headingDescription:
      "Invest your stakes in the world’s major blue-chip stocks",
    min: "min",
    zeroPointZeroSeventyFive: "0.075",
    zeroPointZero: "0.0",
    five: "5",
    one: "1",
    Commission: "Commission",
    minExecution: "min. execution",
    tenMiliSeconds: "10ms",
    Spread: "Spread",
    hundredPlus: "100+",
    UpTo: "Up to",
    Leverage: "Leverage",
    StocksContract: "Stocks Contract",
    Specifications: "Specifications",
    StocksContractSpecifications: `More flexible trading conditions (increased leverage) may apply in case of clients classified as Professional. Please log in to your account and check eligibility for Professional Client, classification, or write to us at`,
    supportMail: "support{'@'}aaafx.com",
    Instrument: "Instrument",
    Bid: "Bid",
    Ask: "Ask",
    ContractSize: "Contract Size",
    MinTradeSize: "Min. Trade Size",
    TickValue: "Tick Value",
    TradingHours: "Trading Hours",
    sevenAM: "7:00 am",
    twelvePM: "12:00pm",
    Treasury_products_description: `Treasury products have a quarterly expiration (please see the table below). Clients that hold an open position on the Expiration Date will be closed at our bid/offer at 21:00 GMT for BUND, the only consequence of this is the client will realize any floating P/L at the time it is closed. There are no rollovers for all Treasury contracts offered.`,
    ExpirationDateCaps: "EXPIRATION DATES",
    Bund: "Bund",
    CORNF: "CORNF",
    WHEATF: "WHEATF",
    SOYF: "SOYF",
    seventhDecemberTwentyTwentyOne: "7-Dec-21",
    twentysixNovemberTwentyOne: "26-Nov-21",
    twentysevenNovemberTwentyOne: "27-Nov-21",
    twentyFourFebruaryTwentyTwo: "24-Feb-22",
    twentyNineDecemberTwentyOne: "29-Dec-21",
    WhatisStocksCFD: "What is Stocks CFDs Trading?",
    WhatisStocksCFDDesc: `Stock CFDs are a low-cost way of taking the advantage of price movements in the share prices of top companies. Major advantage of trading Stock CFDs over Stocks is low capital requirement. When dealing in stocks, you have to pay the full price of a share. However, in case of Stock CFDs, you do not get actual ownership of shares and hence you pay only a small percentage of the price, only to benefit from the price movements. You also get to avail leverage in order to maximise your earning potential.`,
    How_does_it_work: "How does it work?",
    How_does_it_work_desc: `When trading in Stock CFDs, you do not own the stock you are investing in. You simply deposit a small margin, much lower than actual cost of the Stock and avail the benefits of leveraged trading. Hence you get to speculate on the price movements of the Stocks which have publicly available information, at a miniscule cost.The profits or losses are calculated by subtracting the buying price and from the selling price and then multiplying the result with your position size.For example, to buy the equivalent of 100 Facebook stock CFDs with us, you may only need a deposit of 20% of the total position value as compared to what you would pay to buy physical shares.Let's say the share price is $320. Now you would only need to deposit $6400 of margin with us (20% of $32,000 = $6400), excluding the commission.On the other hand, if you had to but the same number of shares directly from a share broker, you would have to pay the entire $32,000, plus commissions and taxes.`,
    Account: "Account",
    Types: "Types",
    ECN: "ECN",
    plus: "plus",
    zulu: "zulu",
    DepositMin: "Deposit (min.)",
    hundred: "100",
    tenThousand: "10,000",
    CommissionPerLakh: "Commission (per $100k)",
    zeroPointZeroNinetyThree: "0.093",
    SpreadMin: "Spread (min.)",
    CompareAccountTypes: "Compare Account Types",
    OurOnline: "Our Online",
    Trading: "Trading",
    Platforms: "Platforms",
    Desktop: "Desktop",
    WebTrader: "Web Trader",
    Android: "Android",
    iOS: "iOS",
    LeranMore: "LEARN MORE",
    NegativeBalance: "Negative Balance",
    Protection: "Protection",
    ReliableProtectionHeading:
      "Reliable protection of your funds on AAAFx Accounts",
    ReliableProtectionDescription:
      "We always endeavor to work towards the best interest of our clients. While trading in volatile times, if your account goes into negative, we have got your back! We will reset your negative balance to zero, so that you do not bear any extra loss. You can deposit and start trading again. Hence, you cannot lose more than what you deposit.",
    OpenLiveAccount: "OPEN LIVE ACCOUNT",
    OpenDemoAccount: "OPEN DEMO ACCOUNT",
  },
  referAndEarnPage: {
    heading: "Refer And Earn",
    headingDescription: "The more you share, the more you get",
    startsWithSharing: "It all starts with sharing!",
    startsWithSharingDescOne:
      "Know someone who you want to introduce to trading? Well, they could make you money. Invite your friends to join AAAFx through your unique referral link. You and your referral shall earn $25 each.",
    startsWithSharingDescTwo:
      "Without incurring any extra cost, simply increase your income. The more friends you refer, the more you earn!",
    startsWithSharingDescThree:
      "Always stay on top of your account with our real-time reporting tools and monitor the status of your referrals to maximize your earnings:",
    startsWithSharingDescThreePointOne:
      "Get your unique referral link as and when required.",
    startsWithSharingDescThreePointTwo: "See how much you’ve earned till date",
    startsWithSharingDescThreePointThree: "Check referral history",
    startsWithSharingDescThreePointFour: "Access to statistics at all times",
    How_to_get_started: "How to get started?",
    How_to_get_started_pointOne: "Log into your Secure Client Area",
    How_to_get_started_pointTwo:
      "Get your referral link and share with your friends.",
    How_to_get_started_pointThree:
      "Earn $25 as your referrals sign up and qualify.",
    WhatDoYourReferrals: "What do your referrals have to do?",
    WhatDoYourReferralsDescOne:
      "Your referrals simply have to use your referral link to register with us. If they miss doing it, that is not a problem! They can",
    WhatDoYourReferralsDescTwo:
      "simply come over Live Chat and let us know that they came through you. We will get them added to your Referrals list.",
    Benefits_to_you: "Benefits to you",
    Benefits_to_you_pointOne: "Unlimited referral earnings.",
    Benefits_to_you_pointTwo:
      "After you have referred 10 clients, you can either continue in Refer and Earn plan or switch to the IB program.",
    Benefits_to_you_pointThree: "Live tracking of your earnings.",
    Benefits_to_you_pointFour:
      "Attract clients with our lucrative promotional offers including bonus.",
    Benefits_to_you_pointFive:
      "24/5 customer support and Personal account manager.",
    Benefits_to_your_referrals: "Benefits to your referrals",
    Benefits_to_your_referrals_pointOne:
      "Enjoy our impeccable trading conditions.",
    Benefits_to_your_referrals_pointTwo:
      "Earn the Referral Reward, just like the referrer.",
    Benefits_to_your_referrals_pointThree:
      "Take benefits of our fully integrated accounts with ZuluTrading.",
    Benefits_to_your_referrals_pointFour:
      "Take advantage of our promotions, including bonus and Loyalty Program.",
    Benefits_to_your_referrals_pointFive:
      "Use their own referral link and bring their own clients.",
    How_is_AAAFx_Refer_and: "How is AAAFx Refer and ",
    Earn_Plan_different_from_the_IB_Program:
      "Earn Plan different from the IB Program?",
    How_is_AAAFx_Refer_desc:
      "While both Programs sound similar, they are quite different from each other. Here are the key differences",
    Feature: "FEATURE",
    referandEarnPlan: "REFER AND EARN PLAN",
    IBProgram: "IB PROGRAM",
    Earnings: "Earnings",
    EarningsPointOneP1: "$25 to you and your referral; up to",
    EarningsPointOneP2: "10 clients (changes to $20 after",
    EarningsPointOneP3: "10 qualified clients)",
    // EarningsPointOneP3: "20% commissions share in Standard IB",
    EarningsPointTwop1: "20% commissions share in Standard IB",
    EarningsPointTwop2: "Program and customized share in",
    EarningsPointTwop3: "Advanced IB Program",
    Earning_frequency: "Earning frequency",
    Earning_frequency_p1: "One time reward",
    Earning_frequency_p2: "Lifelong commissions",
    Live_tracking: "Live tracking",
    Yes: "Yes",
    No: "No",
    View_of_referrals_activities: `View of referrals' activities`,
    Limited: "Limited",
    Full_fledged: "Full - fledged",
    SeparatePortal: "Separate Portal",
    Pay_out_frequency: "Pay out frequency",
    Weekly: "Weekly",
    you_can_read_terms_and_condition:
      "You can read all the Terms and conditions of the Refer and Earn Plan",
    here: "here",
    capsStartRefering: "START REFERRING",
    readyToGoBeyondEarnPlan: "Ready to go beyond Refer and Earn Plan?",
    signUpAsIBWithUs: "Sign up as an IB with us. Read all benefits",
  },
  commoditiesPage: {
    Forex: "Forex",
    Indices: "Indices",
    Commodities: "Commodities",
    Stocks: "Stocks",
    Cryptocurrencies: "Cryptocurrencies",
    heading: "COMMODITIES",
    headingDescription:
      "Build-up Your diverse portfolio by trading major commodities",
    min: "min",
    hundred: "100",
    tenThousand: "10,000",
    CommissionPerLakh: "Commission (per $100k)",
    zeroPointNinentyNine: "0.99",
    zeroPointSeventyFour: "0.74",
    Commission: "Commission",
    zeroPintZero: "0.0",
    Spread: "Spread",
    execution: "execution",
    tenMiliSec: "10ms",
    ten: "10",
    Up_to: "Up to",
    twenty: "20",
    Leverage: "Leverage",
    CommoditiesContract: "Commodities Contract",
    Specifications: "Specifications",
    CommoditiesContractDesc:
      "More flexible trading conditions (increased leverage) may apply in case of clients classified as Professional. Please log in to your account and check eligibility for Professional Client, classification, or write to us at",
    supportmail: "support{'@'}aaafx.com",
    Instrument: "Instrument",
    Bid: "Bid",
    Ask: "Ask",
    ContractSize: "Contract Size",
    MinTradeSize: "Min. Trade Size",
    TickValue: "Tick Value",
    TradingHours: "Trading Hours",
    sevenAM: "7:00 am",
    twelvePM: "12:00pm",
    TreasuryProductsDescription: `Treasury products have a quarterly expiration (please see the table below). Clients that hold an open position on the Expiration Date will be closed at our bid/offer at 21:00 GMT for BUND, the only consequence of this is the client will realize any floating P/L at the time it is closed. There are no rollovers for all Treasury contracts offered.`,
    capsExpirationDates: "EXPIRATION DATES",
    Bund: "Bund",
    cornf: "CORNF",
    wheatf: "WHEATF",
    soyf: "SOYF",
    seventhDecemberTwentyTwentyOne: "7-Dec-21",
    twentysixNovemberTwentyOne: "26-Nov-21",
    twentysevenNovemberTwentyOne: "27-Nov-21",
    twentyFourFebruaryTwentyTwo: "24-Feb-22",
    twentyNineDecemberTwentyOne: "29-Dec-21",
    What_is_Commodities_Trading: "What is Commodities Trading?",
    What_is_Commodities_Trading_desc: `Commodities include precious metals, oil and natural gas. These instruments have lower correlation to all other assets provided in the market and can be a great option for diversification. Given the fundamental volatility of the countries across the world, commodities are considered a ‘safe-haven’ protecting them from sharp price fluctuations.`,
    How_does_it_work: "How does it work?",
    How_does_it_work_desc: `Trading in commodities does not involve getting physical delivery of any asset. The potential for gains lies in the price movements. Since these assets are limited in supply on earth and are used in industrial production, they are exposed to volatility at times, depending upon various economic and political factors. Without actually owning the asset, the trader can simply speculate on its price. With plethora of information available related to economics of various countries, political situations, investments information, supply and demand factors, etc., it has become more convenient than ever for the traders to predict major price movements and speculate on them.Commodities prices are quoted majorly in USD. For example, XAUUSD refers to price of gold in USD. For actually investing in gold, you need some significant capital. But with leveraged trading, you can invest in all precious metals, oil etc with minimal capital outlay.`,
    Account: "Account",
    Types: "Types",
    ECN: "ECN",
    plus: "plus",
    zulu: "zulu",
    DepositMin: "Deposit (min.)",
    spreadMin: "Spread (min.)",
    CompareAccountTypes: "Compare Account Types",
    OurOnline: "Our Online",
    Trading: "Trading",
    Platforms: "Platforms",
    Desktop: "Desktop",
    WebTrader: "Web Trader",
    Android: "Android",
    iOS: "iOS",
    capsLearnMore: "LEARN MORE",
    NegativeBalance: "Negative Balance",
    Protection: "Protection",
    NegativeBalanceDesc1: "Reliable protection of your funds on AAAFx Accounts",
    NegativeBalanceDesc2:
      "We always endeavor to work towards the best interest of our clients. While trading in volatile times, if your account goes into negative, we have got your back! We will reset your negative balance to zero, so that you do not bear any extra loss. You can deposit and start trading again. Hence, you cannot lose more than what you deposit.",
    CapsOpenLiveAccount: "OPEN LIVE ACCOUNT",
    CapsOpenDemoAccount: "OPEN DEMO ACCOUNT",
  },
  careerPage: {
    heading: "Careers",
    headingDescription:
      "We love what we do and think you will love it too! Inclusiveness, Diversity, Innovation, and Loyalty are the reasons why our people love their work and love coming to work every day.",
    Join_us_and_help_us: "Join us and help us add value in everything we do!",
    OurCulture: "Our Culture",
    OurCulturePointOne:
      "No one can whistle a symphony. It takes a whole orchestra to play it. – (H.E.  Luccock)",
    OurCulturePointTwo:
      "At AAAFx we encourage you to be the better version of yourself. Express who you truly are; learn and succeed! Currently, we are employing 80+ people, and aim to further expand our team. Our goal is to create a conducive work environment that gives you a sense of fulfillment.",
    When_our_clients: "When our clients",
    succeed_we_succeed: "succeed, we succeed!",
    succeed_we_succeed_pointOneP1:
      "We are less when we don’t include everyone.",
    succeed_we_succeed_pointOneP2: "- (Stuart Milk)",
    succeed_we_succeed_pointOnedesc1: `We have built a cohesive and inclusive culture that embraces a wide range of employees, who have robust lists of personal achievements and immense team spirit. Inclusiveness fosters a sense of belongingness and respect towards the organization. This is the reason our people feel important and valued.  We strive to create an environment where everyone feels they are working for the same cause together-`,
    succeed_we_succeed_pointOnedesc2: `“excellent performance for a better tomorrow”.`,
    succeed_we_succeed_pointTwoP1: `Strength lies in differences, not in similarities.`,
    succeed_we_succeed_pointTwoP2: `– (Stephen R. Covey)`,
    succeed_we_succeed_pointTwodesc1: `Having a diverse team in terms of gender and culture is essential for us to have a wider and global impact. Our principle is to have equal proportions of male and female team members with respect and acceptance. Further, by keeping physical abilities, racism, and religious beliefs aside, we coexist and flourish in a nurturing environment. Every individual working here is given equal opportunities, equal pay and helps overcome the societal stereotypes.`,
    succeed_we_succeed_pointThreeP1: `Employee loyalty begins with employer loyalty.`,
    succeed_we_succeed_pointThreeP2: ` – (Harvey Mackay)`,
    succeed_we_succeed_pointThreedesc: `Without the support of our team, we could not have reached where we are. Our brand thrives on loyalty of its people, be it clients or our employees. Loyalty is not just an ordinary quality; it is a force that drives team members to do their best and perform to the highest standards possible. Further, it is often said that Employee loyalty is infectious. It starts from the top and goes across all sections of our team. Our team takes pride in what they do, and they are committed to give their 100%.`,
    succeed_we_succeed_pointFourP1: `Innovation is the only way to win.`,
    succeed_we_succeed_pointFourP2: "– (Steve Jobs)",
    succeed_we_succeed_pointFourdesc: `We promote out of the box thinking at all levels to bring out the best in our people. Creativity is essential to produce new things using imagination and then the unique skill set of our people polishes them further. This is something that has led us to improve customer relationships, increase productivity, provide better service, reduce costs, and build a strong brand together.`,
    Rewards_and_Benefits: `Rewards and Benefits`,
    Rewards_and_Benefits_PointOne:
      "The reward for work well done is the opportunity to do more. – (Jonas Salk)",
    Rewards_and_Benefits_PointTwo: `Want to spend more time together along with your family, or on your health and fitness? Ready to develop your profession however nonetheless keep a balance? We not only provide our employees remuneration commensurate with industry standards, but also a flexible working environment, lucrative rewards, and various modes of appreciation and recognition.`,
    CurrentVacancies: "Current Vacancies",
    CurrentVacanciesDescOne: `Join us to become a part of an outstanding work force, that is driven only and only by excellence and commitment amidst a cohesive team environment. Everyone who works with AAAFx feels valued at work!`,
    CurrentVacanciesDescTwo: `Learn what it takes to turn out to be one of us and how you can best prepare. We are looking forward to receiving your application and hearing from you.`,
    ReadyForChallengesReadyForYou: `If you are ready for challenges, We’re ready for you`,
    CustomerSupportGreece: `Customer Support Executive, Greece`,
    What_the_job_looks_like: `What the job looks like?`,
    What_the_job_looks_like_pointOne: `Providing Clients support on Chat, emails, and phone.`,
    What_the_job_looks_like_pointTwo: `Looking into client queries responsibly and pro-actively.`,
    What_the_job_looks_like_pointThree: `Investigating trades of clients, when required.`,
    What_the_job_looks_like_pointFour: `Communicating with clients in a soft, friendly and highly professional manner.`,
    What_the_job_looks_like_pointFive: `Working closely with the management on short-term and long-term goals and objectives.`,
    What_the_job_looks_like_pointSix: `Taking notes of clients’ requests and passing them on pro-actively to the relevant teams.`,
    What_the_job_looks_like_pointSeven: `Following up with allied teams, as and when required to deliver excellent service to clients.`,
    What_the_job_looks_like_pointEight: `To ensure highest levels of customer service getting delivered to clients.`,
    What_do_we_look_for: "What do we look for?",
    What_do_we_look_for_pointOne: `Strong presence of mind problem-solving abilities.`,
    What_do_we_look_for_pointTwo: `Excellent Communication Skills.`,
    What_do_we_look_for_pointThree: `Strong Team Spirit`,
    What_do_we_look_for_pointFour: `Positive Attitude along with calm and composed behavior.`,
    What_do_we_look_for_pointFive: `Prior experience in Investment Finance or Customer Supportrole.`,
    What_do_we_look_for_pointSix: `Professionalism, honesty and Integrity.`,
    applyForTheJob: `APPLY FOR THE JOB`,
    FinanceExecutiveGreece: "Finance Executive, Greece",
    What_the_job_looks_like_point1: `Assisting in making quarterly and annual budgets`,
    What_the_job_looks_like_point2: `Managing records and receipts`,
    What_the_job_looks_like_point3: `Preparing Budget Vs Actual Analysis and Reports`,
    What_the_job_looks_like_point4: `Reconciling daily, monthly, quarterly, and yearly transactions`,
    What_the_job_looks_like_point5: `Preparing quarterly Balance sheets`,
    What_the_job_looks_like_point6: `Processing invoices`,
    What_the_job_looks_like_point7: `Preparing Cash Flow Statements for the Department`,
    What_the_job_looks_like_point8: `Being a point of contact for other departments for Finance related matters.`,
    What_the_job_looks_like_point9: `Clearing employee reimbursements.`,
    What_the_job_looks_like_point10: `Calculating and Publishing Financial Ratios`,
    What_the_job_looks_like_point11: `Providing assistance in Audits, as and when required.`,
    What_the_job_looks_like_point12: `Brining on board ideas and suggestions for efficient funds management`,
    What_do_we_look_for_point1: `Passion for Finance & Accounting`,
    What_do_we_look_for_point2: `Full knowledge of ERP and MS Office`,
    What_do_we_look_for_point3: `Accounting Masters and minimum experience of 3 years working with a Global Broker.`,
    What_do_we_look_for_point4: `Strong Analytical Skills`,
    What_do_we_look_for_point5: `Excellent Team Player`,
    What_do_we_look_for_point6: `Positive Attitude`,
    DataAdministratorGreece: `Data Administrator, Greece`,
    What_the_job_looks_like_p1: `Control access permissions and privileges.`,
    What_the_job_looks_like_p2: `Provide support and assistance to Developers.`,
    What_the_job_looks_like_p3: `Monitor and maintain system health and security.`,
    What_the_job_looks_like_p4: `Design backup processes for server and associated data.`,
    What_the_job_looks_like_p5: `Create accounts for all users and assign security levels.`,
    What_the_job_looks_like_p6: `Provide end-to-end technical support and problem resolution.`,
    What_the_job_looks_like_p7: `Schedule and perform regular server maintenance.`,
    What_the_job_looks_like_p8: `Create database management procedures.`,
    What_the_job_looks_like_p9: `Prepare statistical information for internal and external use.`,
    What_the_job_looks_like_p10: `Evaluate data analysis models and procedures.`,
    What_the_job_looks_like_p11: `Participate in company’s cross-training program.`,
    What_do_we_look_for_p1: `Bachelor’s Degree in Computers.`,
    What_do_we_look_for_p2: `1+ years’ experience in similar role.`,
    What_do_we_look_for_p3: `Advanced knowledge of database structure and theory.`,
    What_do_we_look_for_p4: `Experience with MySQL and MSSQL.`,
    What_do_we_look_for_p5: `Understanding of Database backup procedures.`,
    What_do_we_look_for_p6: `PostgreSQL certification preferred.`,
    What_do_we_look_for_p7: `Ability to work independently with minimal supervision and assistance.`,
    How_to_Apply: `How to Apply?`,
    How_to_Apply_p1: `If you feel you can fit well in any of the above roles, simply click on the ‘Apply’ button and send us your `,
    How_to_Apply_p2: `Resume. Our HR Associate will get back to you.`,
  },
  autoChartistPage: {
    heading: `Autochartist`,
    headingDesc: `Lets you invest your trading time judiciously`,
    subHeadingOne: `Access one of the most popular trading tools free at AAAFx`,
    subHeadingOneP1: `Make informed decisions as Autochartist analyses all charts and patterns for you.`,
    subHeadingOneP2: `If you are a trader, you know the value of time like no one else. We present to you an excellent tool which can save all your time and energy and do multiple kinds of analysis for you.`,
    subHeadingTwo: `How does Autochartist work?`,
    subHeadingTwoP1: `Never miss an opportunity!`,
    subHeadingTwoP2: `Autochartist works on three kinds of patterns:`,
    ChartPatterns: "Chart Patterns",
    FibonacciPatterns: "Fibonacci Patterns",
    KeyLevels: "Key Levels",
    subHeadingTwoDesc: `It monitors the market and keeps sending alerts for trading opportunities. Let the technology work for you 24 hours a day, nonstop.`,
    One_stop_solution: `One stop solution for Market Analysis`,
    One_stop_solution_p1: `Identification of Support and Resistance Levels`,
    One_stop_solution_p2: `Real time Alerts to excessive movements`,
    One_stop_solution_p3: `In depth analysis of Macro economic news`,
    One_stop_solution_p4: `Identification of Support and Resistance Levels`,
    Single_Screen_access_to_everythinig: `Single Screen access to everythinig`,
    Get_simply_plugged_in_to_MT4_and_MT5:
      "Get simply plugged in to MT4 and MT5",
    API_Driven_Technology_free_from_manual_errors:
      "API Driven Technology, free from manual errors",
    All_rounder_for_all_types_of_Traders:
      "All-rounder for all types of Traders",
    Autochartist_Volatility_Analysis: "Autochartist Volatility Analysis",
    Autochartist_Volatility_Analysis_p1: `Build your trading strategy based on facts.`,
    Autochartist_Volatility_Analysis_p2: `Know the best time to trade an instrument by gaining knowledge of its most and the least volatile times during the day.`,
    Autochartist_Volatility_Analysis_p3: `With this tool, you get to see the following`,
    Autochartist_Volatility_Analysis_sub_p1: `Expected price ranges in various timeframes`,
    Autochartist_Volatility_Analysis_sub_p2: `Expected price movement by hour of the day`,
    Autochartist_Volatility_Analysis_sub_p3: `Expected price movement by day of the week`,
    Know_more_here: "Know more here",
    AutochartistRiskCalculator: `Autochartist Risk Calculator`,
    AutochartistRiskCalculatorP1: `Measure your Risk before trading.`,
    AutochartistRiskCalculatorP2: `Now you can be always aware of the ideal trading volume for you, keeping in mind your risk appetite.`,
    AutochartistRiskCalculatorP3: `This tool shows you the expected price movement ranges`,
    AutochartistRiskCalculatorSubP1: `Set the desired entry level and stop loss level`,
    AutochartistRiskCalculatorSubP2: `Enter the amount of money you are willing to risk on the trade`,
    AutochartistRiskCalculatorSubP3: `The Calculator will tell you the position size to take`,
    CapsOpenLiveAccount: "OPEN LIVE ACCOUNT",
    CapsOpenDemoAccount: "OPEN DEMO ACCOUNT",
  },
  cryptoCurrency: {
    innerPages: {
      forex: "Forex",
      indices: "Indices",
      commodities: "Commodities",
      stocks: "Stocks",
      crypto: "Cryptocurrencies",
    },
    title: "CRYPTOCURRENCIES",
    heading:
      "Trade the most powerful cryptocurrencies on our trading platforms",
    min: "min .",
    tradeSize: "Trade Size",
    execution: "execution",
    paris: "Pairs",
    leverage: "Leverage",
    upTo: "Up to",
    contract: "Cryptocurrencies Contract",
    specification: "Specifications",
    pTag: "More flexible trading conditions (increased leverage) may apply in case of clients classified as Professional. Please log in to your account and check eligibility for Professional Client, classification, or write to us at",
    email: "support{'@'}aaafx.com",
    table: {
      heading: {
        instrument: "Instrument",
        bid: "Bid",
        ask: "Ask",
        spread: "Spread",
        contractSize: "Contract Size",
        tradeSize: "Min. Trade Size",
        tickValue: "Tick Value",
        tradingHours: "Trading Hours",
      },
    },
    trading: "What is Cryptocurrency Trading?",
    pTag1:
      "Cryptocurrencies are essentially digital assets secured by cryptography  that move through a decentralised electronic network. These assets are not backed by or not convertible into any commodity. They work via a public ledger, based on Blockchain technology. Bitcoin, released in 2009, is the first decentralized cryptocurrency. Since then, many other cryptocurrencies have been created. These coins have really been a game changer in the financial markets.",
    howItWork: "How does it work?",
    pTag2:
      "The traditional fiat currencies are generally influenced by the economic and political events happening across the globe. However, since crypto currencies are not related to any particular country and are generally decentralised, their prices tend to move independently. They are a high grade volatile class of assets, which makes it imperative for traders to weigh the risk before trading in them. We offer CFDs of cryptos, which means that you can simply trade in cryptos without having to own them. The profits and losses owing to price differential are simply adjusted in your account.These instruments appear as a pair, quoted against USD. For example, BTCUSD. The price of this pair will imply how many USDs are required against 1 BTC.",
    account: "Account",
    types: "Types",
    ecn: "ECN",
    plus: "plus",
    zulu: "zulu",
    deposit: "Deposit",
    commission: "Commission",
    compare: "Compare Account Types",
    ourOnline: "Our Online",
    tradingText: "Trading",
    platform: "Platforms",
    desktop: "Desktop",
    webTrade: "Web Trader",
    android: "Android",
    ios: "iOS",
    learn: "LEARN MORE",
    negative: "Negative Balance",
    protection: "Protection",
    pSubTag: "Reliable protection of your funds on AAAFx Accounts",
    pTag3:
      "We always endeavor to work towards the best interest of our clients. While trading in volatile times, if your account goes into negative, we have got your back! We will reset your negative balance to zero, so that you do not bear any extra loss. You can deposit and start trading again. Hence, you cannot lose more than what you deposit.",
    live: "OPEN LIVE ACCOUNT",
    demo: "OPEN DEMO ACCOUNT",
  },
  ib: {
    whiteLabel: "White Label",
    pamm: "PAMM/MAM",
    title: "Introducing Broker",
    heading: "Develop a permanent source of income for yourself",
    h3Tag:
      "Success today is about building a strong network, let your network work on earning money for you, even when you sleep!",
    pTag: "Want to bring out the best in your marketing skills? AAAFx has brought up an enriching program for our clients to spread the word about our fantastic platform and earn unlimited income. Simply invite traders to us and earn commission when they trade!",
    h3Tag1: "Spread the word- Create an impact in the trading community",
    pTag1:
      "We offer outstanding trading conditions and tools for traders and help our IB partners grow along with their clients:",
    ul: {
      li: "High liquidity for trading",
      liTag: "Zero commissions on Forex",
      liTag1: "Raw Spreads",
      liTag2: "High Leverage up to 500X",
      liTag3: "Trusted and reliable broker",
      liTag4: "Access to Trading tools like Auto chartist",
      liTag5: "Direct access to Zulu Trade",
      liTag6: "Cutting Edge Technology",
    },
    question: {
      ques: "Why should you join in as AAAFx IB?",
      point:
        "Reward for your smart-work and consistency can be huge if you keep growing our trading community, helping them make use of diverse features of our trading platforms. At AAAFx, we offer the most customized and the simplest IB program. Not all IBs are the same. But we have something to offer for everyone.",
      point1: "Hence, we have made two categories of IB programs:",
      point2:
        "Standard IB Program- Straightaway get 20% of our commission earnings from your clients. This is useful for a beginner IB who wants to start afresh or can start to bring a limited volume of clients to us.",
      point3:
        "Advanced IB Program- This is a highly rewarding and customized IB program for our bigger IBs. This is useful for someone with an substantial client base who can bring in larger volume.",
      point4:
        "You can move from Standard IB Program to the Advanced IB Program, once you have made your network large enough.",
    },
    signUp: "Sign-up as IB",
    referralLink: "Share Referral link",
    clients: "Get your clients",
    earn: "Earn as they trade",
    benefits: "Other benefits of our IB Program",
    zero: "Earn with Zero Investment or Experience",
    ibCommission: "Weekly IB commissions pay-outs",
    earning: "Unlimited Earnings potential",
    customized: "Highly customized and rewarding Advanced IB Program",
    ultraFast: "Ultra-fast withdrawals of IB commissions",
    easyUser: "Easy User Interface of IB Portal",
    accountManager: "Dedicated Account Manager",
    helpful: "Helpful and Free Marketing Material",
    h6Tag:
      "Our Advanced IB Program is highly customizable with high income potential. If you think you have that potential, get in touch with us to know more. Contact Us now",
    click: "here",
    orWrite: "or write to us at",
    email: "support{'@'}aaafx.com.",
  },
  mt4PageData: {
    MT4: "MT4",
    MetaTrade4: "MetaTrader 4",
    MT5: "MT5",
    ActTrader: "ActTrader",
    ZuluTrade: "ZuluTrade",
    MetaTrade4DescOne: `The MetaTrader 4 platform created back in 2002 by MetaQuotes. MT4 platform is considered the gold-standard software among the wide array of trading platforms; used for trading forex, CFDs, stocks and various popular crypto currencies.`,
    MetaTrade4DescTwo: `AAAFx MT4 platform provides a user-friendly interface, enabling users to understand the platform's features with absolute ease. It assimilates a user's requirements and offers the best possible solution by providing the ability to apply various analysis tools, run trading robots (expert advisors), and copy trades from others.`,
    MetaTrade4DescThree: `Enhance your trading experience on a bigger screen with greater functionalities and greater focus by downloading the MT4 platform on your desktop or simply access our web-based platform.`,
    Steps_to_download_MT4_Desktop: `Steps to download MT4 Desktop`,
    Steps_to_download_MT4_Desktop_point1: `1. Download the platform from above link.`,
    Steps_to_download_MT4_Desktop_point2: `2. Run the .exe file`,
    Steps_to_download_MT4_Desktop_point3: `3. Go to File> Open an account”`,
    Steps_to_download_MT4_Desktop_point4: `4. Click on the Add New Broker and type AAAFX. Select AAAFX-FX-DEMO for demo account and AAAFX-FX-REAL for live account.`,
    Steps_to_download_MT4_Desktop_point5: `5. Click on the Scan button. The server is now added.`,
    Steps_to_download_MT4_Desktop_point6: `6. Click on next> select “Existing trade account” check box and enter your login ID and Password. Click on Finish.`,
    Steps_to_uninstall_MT4_Desktop: "Steps to uninstall MT4 Desktop",
    Steps_to_uninstall_MT4_Desktop_point1: `1. Click on Start button in Windows`,
    Steps_to_uninstall_MT4_Desktop_point2: `2. The MT4 application will be included in list of programs.`,
    Steps_to_uninstall_MT4_Desktop_point3: `3. Right click on the application name and select 'Uninstall'.`,
    Steps_to_log_into_Webbased_MT4: `Steps to log into Web-based MT4`,
    Steps_to_log_into_Webbased_MT4_point1: `1. On the Web-Trader, go to “File” option on the menu bar and select “Login to Trade account”`,
    Steps_to_log_into_Webbased_MT4_point2: `2. Enter trading account ID and password.`,
    Steps_to_log_into_Webbased_MT4_point3: `3. Select server as “AAAFX-FX-REAL” for live account and “AAAFX-FX-DEMO” for demo account`,
    Steps_to_log_into_Webbased_MT4_point4: `4. Then select the platform MT4 and click on “OK”.`,
    AAAFx_mobile_trading_application_description: `Take your trading euphoria anywhere you go with all the trading tools and indicators available for AAAFx mobile trading application.`,
    Steps_to_download_MT4_Android: "Steps to download MT4 Android",
    Steps_to_download_MT4_Android_point1: `1. Download the application from above link.`,
    Steps_to_download_MT4_Android_point2: `2. Click on three horizontal lines in Menu and go to “manage accounts”/ “Get started”.`,
    Steps_to_download_MT4_Android_point3: `3. Click on the “+” sign on the top right corner.`,
    Steps_to_download_MT4_Android_point4: `4. Select “Login to an existing account”`,
    Steps_to_download_MT4_Android_point5: `5. Under 'Find your broker' select AAAFX-FX-DEMO for demo account and AAAFX-FX-REAL for live account. Now enter your login ID and password and click on login.`,
    Steps_to_uninstall_MT4_Android: "Steps to uninstall MT4 Android",
    Steps_to_uninstall_MT4_Android_point1: `1. On the Menu, go to Settings > Applications > Manage applications`,
    Steps_to_uninstall_MT4_Android_point2: `2. Find MT4 listed in there and tap on its name.`,
    Steps_to_uninstall_MT4_Android_point3: `3. Tap 'Uninstall'.`,
    Steps_to_download_MT4_iOS: `Steps to download MT4 iOS`,
    Steps_to_download_MT4_iOS_point1: `1. Download the application from above link.`,
    Steps_to_download_MT4_iOS_point2: `2. Click on "Settings" and go to "New Account" Option,`,
    Steps_to_download_MT4_iOS_point3: `3. Then click "Login to an existing Account"`,
    Steps_to_download_MT4_iOS_point4: `4. Enter “AAAFX” under find broker.`,
    Steps_to_download_MT4_iOS_point5: `5. Select AAAFX-FX-DEMO for demo account and AAAFX-FX-REAL for live account.`,
    Steps_to_download_MT4_iOS_point6: `6. Enter your Login ID and Password and click on "Sign in"`,
    Steps_to_uninstall_MT4_iOS: `Steps to uninstall MT4 iOS`,
    Steps_to_uninstall_MT4_iOS_point1: `1. Long press on the MT4 application icon until all the icons on your screen begin to shake.`,
    Steps_to_uninstall_MT4_iOS_point2: `2. Click the 'x' in the upper left-hand corner of the icon for the MT4 application.`,
    take_trading_experience_to_next_level: `Take your MT4 platform trading experience with AAAFx to the next level with the following features`,
    Why_AAAFxs_MT4: `Why AAAFx's MT4?`,
    Why_AAAFxs_MT4_point1: `Associated with the largest trading community of MQL`,
    Why_AAAFxs_MT4_point2: `20+ graphical objects for in-depth analysis`,
    Why_AAAFxs_MT4_point3: `Real-time market price`,
    Why_AAAFxs_MT4_point4: `Simple to understand interface`,
    Why_AAAFxs_MT4_point5: `Compatible on windows, iphone, android or simply trade directly from the web`,
    Why_AAAFxs_MT4_point6: `Ready-to-use Scripts; allowing an automated trading experience`,
    Why_AAAFxs_MT4_point7: `1000+ free and downloadable indicators/EAs offered by the MQL4`,
    Why_AAAFxs_MT4_point8: `Fastest execution speed with multiple servers across the world`,
    Why_AAAFxs_MT4_point9: `70+ forex currency pairs, global indices, and commodities available to trade`,
    Why_AAAFxs_MT4_point10: `Market execution and 4 types of pending orders available`,
    capsOpenLiveAccount: `OPEN LIVE ACCOUNT`,
    capsOpenDemoAccount: `OPEN DEMO ACCOUNT`,
  },
  mt5PageData: {
    MT4: "MT4",
    MetaTrade5: "MetaTrader 5",
    MT5: "MT5",
    ActTrader: "ActTrader",
    ZuluTrade: "ZuluTrade",
    MetaTrade5DescOne: `MT5 platform was released in 2010 supporting the faster than ever processing technology and additional layouts.`,
    MetaTrade5DescTwo: `AAAFx MT5 platform offers an extensive list of the trading instruments including Cryptocurrency CFDs, helping the advanced platform to fulfil its futuristic outlook.`,
    download_mt5_preferred_device: `It's always better on a bigger screen. Download our MT5 platform on your preferred device.`,
    Steps_to_download_MT5_Desktop: `Steps to download MT5 Desktop`,
    Steps_to_download_MT5_Desktop_point1: `1. Download the platform from above link.`,
    Steps_to_download_MT5_Desktop_point2: `2. Run the .exe file`,
    Steps_to_download_MT5_Desktop_point3: `3. Go to File> Open an account”`,
    Steps_to_download_MT5_Desktop_point4: `4. Type AAAFX and in 'Add New Broker' and click on 'Find your broker'.`,
    Steps_to_download_MT5_Desktop_point5: `5. Select “AAAFX” and click on “Next”`,
    Steps_to_download_MT5_Desktop_point6: `6. Click on “Connect with an existing trading account” and enter your login details`,
    Steps_to_download_MT5_Desktop_point7: `7. To log in to a Live account select server name 'AAAFX-FX-REAL' and 'AAAFX-FX-DEMO' for Demo account from the drop-down menu and click on “Finish”.`,
    Steps_to_uninstall_MT5_Desktop: `Steps to uninstall MT5 Desktop`,
    Steps_to_uninstall_MT5_Desktop_point1: `1. Click on Start button in Windows`,
    Steps_to_uninstall_MT5_Desktop_point2: `2. The MT5 application will be included in list of programs.`,
    Steps_to_uninstall_MT5_Desktop_point3: `3. Right click on the application name and select 'Uninstall'.`,
    Steps_to_log_into_Webbased_MT5: `Steps to log into Web-based MT5`,
    Steps_to_log_into_Webbased_MT5_point1: `1. On the Web-Trader. go to “File” option on the menu bar and select “Login to Trade account”.`,
    Steps_to_log_into_Webbased_MT5_point2: `2. Enter trading account ID and password.`,
    Steps_to_log_into_Webbased_MT5_point3: `3. Select server as “AAAFX-FX-REAL ” for live account and “AAAFX-FX-DEMO” for demo account`,
    Steps_to_log_into_Webbased_MT5_point4: `4. Then select the platform MT5 AND click on “OK”`,
    AAAFx_mobile_trading_application: `Take your trading euphoria anywhere you go with all the trading tools and indicators available for AAAFx mobile trading application.`,
    Steps_to_download_MT5_Android: `Steps to download MT5 Android`,
    Steps_to_download_MT5_Android_point1: `1. Download the application from above link.`,
    Steps_to_download_MT5_Android_point2: `2. Click on three horizontal lines in Menu and go to manage accounts”/ “Get started”.`,
    Steps_to_download_MT5_Android_point3: `3. Click on the “+” sign on the top right corner.`,
    Steps_to_download_MT5_Android_point4: `4. Under 'Find your broker' select AAAFX-FX-DEMO for demo account and AAAFX-FX-REAL for live account. Now enter your login ID and password and click on login.`,
    Steps_to_uninstall_MT5_Android: `Steps to uninstall MT5 Android`,
    Steps_to_uninstall_MT5_Android_point1: `1. On the Menu, go to Settings > Applications > Manage applications`,
    Steps_to_uninstall_MT5_Android_point2: `2. Find MT4 listed in there and tap on its name.`,
    Steps_to_uninstall_MT5_Android_point3: `3. Tap 'Uninstall'.`,
    Steps_to_download_MT5_iOS: `Steps to download MT5 iOS`,
    Steps_to_download_MT5_iOS_point1: `1. Download the application from above link.`,
    Steps_to_download_MT5_iOS_point2: `2. Click on "Settings" and go to "New Account" Option,`,
    Steps_to_download_MT5_iOS_point3: `3. Then click "Login to an existing Account"`,
    Steps_to_download_MT5_iOS_point4: `4. Enter “AAAFX” under find broker. br 5. Select AAAFX-FX-DEMO for demo account and AAAFX-FX-REAL for live account.`,
    Steps_to_download_MT5_iOS_point5: `5. Enter your Login ID and Password and click on "Sign in"`,
    Steps_to_uninstall_MT5_iOS: `Steps to uninstall MT5 iOS`,
    Steps_to_uninstall_MT5_iOS_point1: `1. Long press on the MT5 application icon until all the icons on your screen begin to shake.`,
    Steps_to_uninstall_MT5_iOS_point2: `2. Click the 'x' in the upper left-hand corner of the icon for the MT5 application.`,
    trading_experience_the_next_level: `Take your MT5 platform trading experience with AAAFx to the next level with the following features:`,
    Why_AAAFxs_MT5: `Why AAAFx's MT5?`,
    TechnicalAnalysisTools: `Technical Analysis Tools`,
    TechnicalAnalysisToolsPoint1: `21-different time frames so that you never lose even a second of opportunity`,
    TechnicalAnalysisToolsPoint2: `30+ built-in indicators and 40+ technical objects`,
    TechnicalAnalysisToolsPoint3: `Availability of historical chart data of up to 30 years`,
    TechnicalAnalysisToolsPoint4: `6 types on pending order types available along with the market execution`,
    TechnicalAnalysisToolsPoint5: `In-built and modifying option for Scripts`,
    FundamentalAnalysisTools: `Fundamental Analysis Tools`,
    FundamentalAnalysisToolsPoint1: `Built-in Economic Calendar; keeping you updated about every volatile event`,
    FundamentalAnalysisToolsPoint2: `Ability to download the detailed account statement for customizable time period`,
    FundamentalAnalysisToolsPoint3: `Depth of market tool giving you an edge on your trading strategies`,
    capsOpenLiveAccount: `OPEN LIVE ACCOUNT`,
    capsOpenDemoAccount: `OPEN DEMO ACCOUNT`,
  },
  ourServicesPage: {
    heading: `Our Servers`,
    headingDescription: `Robust Set up for a Flawless Experience`,
    EquinixDataCenters: `Equinix Data Centers`,
    EquinixDataCentersDescription: `In our endeavour to provide a world class trading experience to our clients, we rely on nothing less than the best technology and infrastructure in the world. We aim to provide the most reliable and fastest connectivity when it comes to trade execution and therefore, we use the world’s most advanced global network and server clusters.`,
    What_are_the_advantages_of_Equinix: `What are the advantages of Equinix?`,
    FastestExecution: `Fastest Execution`,
    FastestExecutionDescription: `The Data Center is collocated with our major LPs, thereby providing fastest connectivity possible.`,
    Low_latency_connection: `Low latency connection`,
    Low_latency_connection_description: `The fast connection between our trade servers and LPs through dedicated channel ensures least latency possible.`,
    Uninterrupted_trading: `Uninterrupted trading`,
    Uninterrupted_trading_description: `Instant and seamless connectivity, maximum up time, making your trading experience flawless.`,
    Low_slippage: `Low slippage`,
    Low_slippage_description: `Ultra-fast connection means you get the price you want, with minimal chances of slippage`,
    Easy_connectivity: `Easy connectivity`,
    Easy_connectivity_description: `You do not have to struggle with connectivity when you are connecting with a dense network that works on cutting edge technology`,
    What_is_Equinix: `What is Equinix?`,
    What_is_Equinix_desone: `Equinix, Inc. (NASDAQ: EQIX) is an American multinational company headquartered in Redwood City, California, that specializes in Internet connectivity and data center management.`,
    What_is_Equinix_destwo: `The company is a leader in global co-location with 229 data centers in 27 countries on five continents. Additionally, they have won various awards for reliability and sustainability. Their Data Centers are considered to be the gold standard, and as the most reliable and secure digital infrastructure.`,
    What_is_the_LD4_Data_Center: `What is the LD4 Data Center?`,
    What_is_the_LD4_Data_Center_descOne: `Equinix LD4 data center where most of the AAAFx servers are housed is the Forex Industry’s most prime location for server placement, as it enables a direct straight through connectivity with all major Global liquidity providers. It is over 14,000 sq. ft in size and holds various global certifications, like SOC 1 Type 1, SOC 2 Type 2, ISO 27001, PCI DSS, OHSAS 18001, ISO 9001:2015, ISO 22301, ISO 14001:1015 and ISO 50001.`,
    What_is_the_LD4_Data_Center_descTwo: `The accelerated connection to our liquidity providers through the dense network enables ultra-fast trade execution with least latency.`,
  },
  LoyaltyBonusPageData: {
    heading: `Loyalty Program`,
    headingDescription: `The more you trade, the more rewards you earn automatically!`,
    LoyaltyBonusDescription: `They say loyalty goes both ways. We earn our clients' loyalty through our hard work <br />and transparency, and we believe in returning it through our free Rewards program.0`,
    What_is_AAAFx_loyalty_program: `What is AAAFx loyalty program?`,
    What_is_AAAFx_loyalty_program_descOne: `Every trade counts in a trader's journey. Each trade you place with us goes a long way in giving you exciting returns through our marvellous Loyalty Program.`,
    What_is_AAAFx_loyalty_program_descTwo: `There is no need to sign up. All of your trades will keep earning your Reward Points (RPs) automatically. Upon gathering certain number of Reward Points (RPs), you can redeem them in several different ways, including cash!`,
    Deposit: `Deposit`,
    Trade: `Trade`,
    Earn_RPs: `Earn RPs`,
    Redeem_RPs: `Redeem RPs`,
    loyalty_program_descOne: `Our loyalty program is suitable for all kinds of`,
    loyalty_program_descTwo: `traders. There are two ways of progressing`,
    loyalty_program_descThree: `through its Tier Levels:`,
    ExpressRoute: `Express Route`,
    ExpressRouteDesc: `Suitable for those traders who like to trade more in one go`,
    SteadyRoute: `Steady Route`,
    SteadyRouteDesc: `For those who like to trade consistently over a longer period of time`,
    OurFabulousFourLoyaltyTiers: `Our Fabulous Four Loyalty Tiers`,
    OurFabulousFourLoyaltyTiersDescOne: `Trade More, Earn More`,
    OurFabulousFourLoyaltyTiersDescTwo: `Its keeps getting bigger and better with every tier you climb up.`,
    OurFabulousFourLoyaltyTiersDescThree: `We have two very simple and flexible routes to qualify for the loyalty tiers. Whichever route you take, we will make sure that`,
    OurFabulousFourLoyaltyTiersDescFour: `you earn the same applicable Loyalty tiers and the same reward points. If you overlap in these two routes, you will be`,
    OurFabulousFourLoyaltyTiersDescFive: `allotted the higher applicable tier out of the two routes.`,
    Redeem_your_Reward_Points: `Redeem your Reward Points`,
    EarnMoreRedeemMore: `Earn More, Redeem More`,
    EarnMoreRedeemMoreDesc: `Flexible and attractive options to convert your Reward points into numerous benefits`,
    Redeem_your_Reward_Points_point1: `Cash (Min. 500 RPs can be redeemed for cash)`,
    Redeem_your_Reward_Points_point2: `Free Basic VPS for 3 months`,
    Redeem_your_Reward_Points_point3: `Free Premium VPS for 3 months`,
    Redeem_your_Reward_Points_point4: `SeatsonAX Subscription for 3 months`,
    Redeem_your_Reward_Points_point5: `Even Faster Withdrawals for the next 20 days`,
    Redeem_your_Reward_Points_point6: `Move to the Next Reward Tier level`,
    Redeem_your_Reward_Points_point7: `Iphone/Ipad or a similar smart device of up to $1200 in value`,
    Redeem_your_Reward_Points_point8: `1 Pip flat spread for 10 trading days`,
    Redeem_your_Reward_Points_point9: `10% extra IB commission for last month`,
    Redeem_your_Reward_Points_point10: `$100 extra for each referral in last month`,
    Redeem_your_Reward_Points_point11: `1 month commission reimbursement`,
    Redeem_your_Reward_Points_point12: `Advance deposit for deposit in transit`,
    Redeem_your_Reward_Points_point13: `Additional bonus in account`,
    Redeem_your_Reward_Points_point14: `1 Month Swap Fee Reimbursement`,
    Redeem_your_Reward_Points_point15: `10% Extra (withdrawable) deposit on the last 5 Deposits`,
    Redeem_your_Reward_Points_point16: `Loss Reimbursement on any one trade in last 20 trading days`,
    Redeem_your_Reward_Points_point17: `Spread reimbursement on 10 loss making trades (in last 20 days)`,
    AdditionalCommAmount: `Additional Comm. Amount`,
    AdvanceDepositAmount: `Advance Deposit Amount`,
    Reimbursement_amount: `Reimbursement amount`,
    readTermsAndConditionHere: `Read terms and conditions of our Loyalty Program`,
    BonusAmount: `Bonus Amount`,
    here: `here`,
    two: "2",
    six: "6",
    seven: `7`,
    ten: `10`,
    sixHundred: `600`,
    sevenHundred: `700`,
    oneThousand: `1000`,
    twoThousand: `2000`,
    twoThousandFivehundred: `2500`,
    threeThousand: `3000`,
    tenThousand: `10,000`,
    twentyFiveThousand: `25,000`,
    rps: `RPs`,
    rpsPerReferral: `RPs per referral`,
    oneusd: `1USD`,
  },
  legalDocument: {
    title: "Legal Documents",
    heading: "We proudly embrace honesty and transparency at AAAFx",
    recommend: "We recommend you to read and understand all the necessary",
    tradingJourney:
      "legal documents before starting your trading journey with us.",
    policy: "Privacy Policy",
    view: "view",
    risk: "Risk Disclaimer",
    clientAgreement: "Retail Client Agreement",
    refundPolicy: "Refund Policy",
    executionPolicy: "Execution Policy",
    conflicts: "Conflicts Of Interest Policy",
    clientAsset: "Client Assets",
    investor: "Investor Compensation Scheme",
    h3Tag: "KEY INFORMATION DOCUMENT – CFDS ON COMMODITIES",
    h3Tag1: "KEY INFORMATION DOCUMENT–CFDS ON INDICES",
    h3Tag2: "KEY INFORMATION DOCUMENT–CFD’s ON SHARES",
    h3Tag3: "KEY INFORMATION DOCUMENT–CFDS ON CURRENCY PAIRS",
    h3Tag4: "MiFID ΙΙ Directive Basics",
  },
  pamm: {
    whiteLabel: "White Label",
    title: "PAMM/MAM",
    programm: "PAMM/MAM Program",
    heading:
      "A perfect opportunity to earn from the years of experience as a successful trader",
    pTag: "At AAAFx, we bring golden opportunity for traders to showcase their trading expertise to the world and earn through their followers. This also gives chance to Investors to choose the best traders to trade for them.",
    account: "What is PAMM or a MAM account?",
    pTag1:
      "This is a form of pooled fund of investments in which a Money Manager trades on the funds collated by his investor clients. With a single account access, the Money Manager gets to place trades in all his investors’ accounts. In PAMM account, the trades get allocated automatically and instantly in the ratio of the relative equity in the investors’ accounts.  In MAM account, the investor can choose the risk level he wants to take and decide upon the trade allocation accordingly.",
    combined: "Why have we combined our PAMM and MAM with ZuluTrade?",
    pTag2:
      "ZuluTrade is synonymous to transparency. When you are pooling funds as an investor and letting some trader trade on your money, you need to have clear visibility of the historic performance of the trader, full flexibility to choose the trade allocations, opportunity to interact with various expert traders, ask them questions, learn from them, etc. We provide you all of these in collaboration with ZuluTrade.",
    advantage: "Advantage to PAMM/MAM Manager",
    pTag3:
      "The PAMM/MAM Manager can take advantage of his expertise to earn higher income through the performance fee charged to his clients. Our PAMM/MAM Managers also get full flexibility to set their terms and conditions related to the trading activity and allocation of profits.  Further, the PAMM/MAM Managers also receive highly attractive IB payouts, in addition to performance fees from their clients.",
    investor: "Advantage to Investors",
    pTag4:
      "The Investors get to choose from a huge range of traders listed on ZuluTrade with AAAFx. They can review their trading performance, interact with them before participating in the PAMM or MAM program. Thereafter, they can simply relax and let the Manager do his job. After funding their account and agreeing to a fully transparent Terms and Conditions document, they can continuously monitor their account’s performance. They also have full control over their account in terms of deposits, withdrawals and disconnecting from the Manager account.",
    aaafxProgramm: "Why AAAFx PAMM/MAM program?",
    ul: {
      liTag: "Collaboration with Largest Social Trading Network",
      liTag1: "Dedicated Account Managers for Investors and Managers",
      liTag2:
        "Flexibility in setting parameters and conditions for the account",
      liTag3: "Ultra-low commissions with raw spreads",
      liTag4: "Security of funds with a trusted broker",
      liTag5: "Small investment required to start",
      liTag6: "Transparency for the investors- no hidden clauses",
      liTag7: "Full control to investors related to deposits and withdrawals",
    },
    minimum: "Minimum requirements",
    liTag: "Total deposit must be a minimum of $5,000.",
    subAccount: "Minimum 4 sub accounts required.",
    eachInvestor:
      "Each investor should sign-up the POA and agree with all the conditions.",
    howTo: "How to Sign up?",
    portal: "Sign-up on the AAAFx portal.",
    build: "Build up a team of investors.",
    sign: "Get the Investors sign the POA.",
    earning: "Start earning profits.",
    titleHeading:
      "Our PAMM/MAM program is highly customizable, hence get in touch with us to know more and to start a",
    contact: "PAMM/MAM account. Contact Us now here or write to us at",
    email: "support{'@'}aaafx.com.",
  },
  indicesPages: {
    innerPages: {
      forex: "Forex",
      indices: "Indices",
      commodities: "Commodities",
      stocks: "Stocks",
      crypto: "Cryptocurrencies",
    },
    title: "INDICES",
    heading: "Grow your trading account by trading global indices",
    min: "min .",
    commission: "Commission",
    execution: "execution",
    spread: "Spread",
    upTo: "Up to ",
    leverage: "Leverage",
    contract: "Indices Contract",
    specification: "Specifications",
    pTag: "More flexible trading conditions (increased leverage) may apply in case of clients classified as Professional. Please log in to your account and check eligibility for Professional Client, classification, or write to us at",
    email: "support{'@'}aaafx.com",
    instument: "Instrument",
    bid: "Bid",
    ask: "Ask",
    size: "Contract Size",
    minTrade: "Min. Trade Size",
    tickValue: "Tick Value",
    hours: "Trading Hours",
    trading: "What is Indices CFD Trading?",
    pTag1:
      "An index represents a separate portfolio with an amalgamation of various companies’ profiles which helps in mitigating the risk profile of a client. Indices trading relieves a trader of the large amount of time to be spent in analysing a single stock. Indices can be of various types, geography based (like Spain Index, German Index, Hong Kong Index) and sector based (like NASDAQ).",
    how: "How does it work?",
    content:
      "The volatility in Stock Indices is driven by political and economic factors and not just the performance of individual stocks. Traders can gain profits by speculating on the movement of stock indices in upwards or downwards direction. The trader does not need to buy all of individual stocks listed on Dow Jones, for example. He can simply trade the Dow Jones CFD. Trading indices is a great way of diversifying your portfolio with a small capital outlay. Traders generally rely on economic performance in regions (for country-based indices) or also the overall performance of the sector (for sector based indices) to assess whether they should go long or short on the index. Index itself is just a number, but the beauty of this product lies in the ability to invest overall in all individual shares of the Index, with a much smaller capital.",
    account: "Account",
    type: "Types",
    ecn: "ECN",
    plus: "plus",
    zulu: "zulu",
    deposit: "Deposit",
    compare: "Compare Account Types",
    online: "Our Online",
    tradingText: "Trading",
    platform: "Platforms",
    desktop: "Desktop",
    webTrade: "Web Trader",
    android: "Android",
    ios: "iOS",
    learn: "LEARN MORE",
    negative: "Negative Balance",
    protection: "Protection",
    pSubTag: "Reliable protection of your funds on AAAFx Accounts",
    workTowards:
      "We always endeavor to work towards the best interest of our clients. While trading in volatile times, if your account goes into negative, we have got your back! We will reset your negative balance to zero, so that you do not bear any extra loss. You can deposit and start trading again. Hence, you cannot lose more than what you deposit.",
    live: "OPEN LIVE ACCOUNT",
    demo: "OPEN DEMO ACCOUNT",
  },
  economicCalander: {
    title: "Economic Calendar",
    heading: "Global events at your fingertips",
    h5Tag:
      "Let our Economic Calendar acquaint you with the all the important financial events impacting global markets",
    news: "Economic calendar, Forex news",
    pTag: "This is an indispensable tool for fundamental analysis of finance markert based on economic news. More than 500 indices of the world’s largest economies are based on real-time data collected from public sources.",
    upNext: "Up next",
    country: "Country",
    selectAll: "Select All",
    clear: "Clear",
    myFilter: "My Filter",
    default: "Default",
    text: "Impact",
    type: {
      all: "All",
      medium: "Medium",
      high: "High",
    },
    saveSetting: "Save setting in my filter",
    apply: "Apply Filter",
    calander: {
      time: "Time",
      country: "Country",
      event: "Event",
      actual: "Actual",
      forecast: "Forecast",
      previous: "Previous",
    },
    head: "Title:",
    category: "Category:",
    desc: "Description:",
    defi: "Definition:",
  },
  faqs: {
    "Getting Started": [
      {
        label: "How to register with AAAFx and verify my account?",
        content: `
          <div class="content">
          <p>For successful verification, the below process is to be followed:</p>
          <ul>
          <li>Step 1: Please provide your personal details including Name, Phone number, Email address etc.</li>
          <li>Step 2: After all the personal details have been furnished, kindly upload a Photo ID Proof (license, passport etc.)</li>
          <li>Step 3: For security purposes, please click a selfie on the spot and upload it on the required page.</li>
          <li>Step 4: At the next step, kindly upload a recent 'Proof of Residence' (issued within 3 months of the current date), containing your full name and address (a utility bill, bank statement, etc.)</li>
          <li>Step 5: Kindly fill in the details and click on Submit.</li>
          </ul>
          </div>
        `,
      },
      {
        label: "Which country do you accept clients from?",
        content: `<div class="content"><p>We accept clients from 27 countries under the European Economic Area (EU and Iceland, Switzerland and Liechtenstein). Clients from Belgium, and the UK are not accepted.</p></div>`,
      },
      {
        label: "Is AAAFx regulated?",
        content: `<div class="content"><p>AAAFx is a regulated financial service provider with licences from HCMC, Greece and FSCA, South Africa.</p></div>`,
      },
      {
        label:
          "Can I sign up without an email address/phone number, I don't have one?",
        content: `<div class="content"><p>Kindly note that you would need an email address/Phone number to sign up with us, as we send email confirmations on the registered email address to verify the request made from the web account. We would request you to provide your phone number as well, so that we can reach you in case of any urgency.</p></div>`,
      },
      {
        label: "What are the documents needed for account verification?",
        content: `<div class="content">
        <p>The following documents will be required to open an account with us:</p>
        <ul>
        <li>1. A valid photo ID Proof (such as passport, driving license, or any photo ID issued by a govt. office)</li>
        <li>2. A recent Residence proof (issued within 3 months), containing full name and address (it could    be a utility bill, bank statement, etc.). You can upload the above-mentioned necessary documents on the web account or email them at support{'@'}aaafx.com.</li>
        </ul>
        </div>`,
      },
      {
        label:
          "What if I need to change my details later? How do I update information on my profile?",
        content: `<div class="content">
        <p>You may change the following personal details by logging into your web account:</p>
        <ul>
        <li>Primary Phone</li>
        <li>Mobile Phone</li>
        <li>Gender</li>
        <li>Marital Status</li>
        </ul>
        <p>You may also change your bank details by logging into your web account. For updating other details, please reach out to us on Live Chat or email at support{'@'}aaafx.com.</p>
        </div>`,
      },
      {
        label: "How many Live accounts can I open?",
        content: `<div class="content"><p>You can open as many accounts as you wish. There is no restriction.</p></div>`,
      },
      {
        label: "In how many base currencies can I open an account?",
        content: `<div class="content"><p>You can open an account with a base currency like USD, EUR and CHF.</p></div>`,
      },
      {
        label: "How much is the minimum deposit to open an account?",
        content: `<div class="content"><p>The minimum deposit is only $100.</p></div>`,
      },
      {
        label: "Do you offer Islamic (swap free) accounts?",
        content: `<div class="content"><p>Yes, you can select Islamic account type while creating the request.</p></div>`,
      },
      {
        label: "Do you allow Hedging?",
        content: `<div class="content"><p>Yes, we do allow Hedging.</p></div>`,
      },
      {
        label: "Do you allow Scalping?",
        content: `<div class="content"><p>We do not allow scalping</p></div>`,
      },
    ],
    "Deposits and Withdrawals": [
      {
        label: "How do I make a deposit in my account?",
        content: `<div class="content">
        <ul>
        <li>1. Log into your web account</li>
        <li>2. Go to Fund tab on left pane</li>
        <li>3. Select the Trading account that you wish to fund.</li>
        <li>4. Choose the preferred payment method you want to use.</li>
        </ul>
        </div>`,
      },
      {
        label: "How can I transfer funds between my accounts?",
        content: `<div class="content">
        <ul>
        <li>1. Log into your web account</li>
        <li>2. Go to Manage Accounts in left pane</li>
        <li>3. Click on 'Internal Transfer' button.</li>
        <li>4. Select the accounts and enter the amount you wish to transfer.</li>
        <li>5. Click Proceed. The funds will be transferred instantly.</li>
        </ul>
        <p>In case of internal transfer between accounts with different base currencies, the amount transferred will be as per the prevalent market prices.</p>
        </div>`,
      },
      {
        label: "How do I withdraw funds from my account?",
        content: `<div class="content">
        <ul>
        <li>1. Log into your web account.</li>
        <li>2. Go to Fund tab on left pane.</li>
        <li>3. Click on Withdrawals tab.</li>
        <li>4. Select the Trading ID from which you wish to make withdrawal.</li>
        <li>5. Enter the amount to be withdrawn and select the mode of withdrawal.</li>
        <li>6. Click on Create Transaction.</li>
        </ul>
        </div>`,
      },
      {
        label: "How long does it take to process a withdrawal?",
        content: `<div class="content"><p>All withdrawals are subject to 24 hours processing time, including weekends.</p></div>`,
      },
      {
        label: "Are there any deposit/withdrawal fees?",
        content: `<div class="content"><p>No, all deposits and withdrawals are free from our side.</p></div>`,
      },
      {
        label:
          "How long does it take to reflect the deposit in my trading account?",
        content: `<div class="content">
        <ul>
        <li><b>Bank Cards: </b>The payments are processed instantly.</li>
        <li><b>Bank Wire: </b>Bank wires transactions usually take 2-3 working days to reach us.</li>
        <li><b>eWallets: </b>The payments are processed instantly. It usually takes up to 1 business day to reflect the amount in your trading account if the deposit is initiated via Skrill.</li>
        <li><b>Cryptocurrencies: </b>Crypto deposits are credited within 30-40 minutes.</li>
        </ul>
        </div>`,
      },
      {
        label: "Do you reimburse the transaction fees?",
        content: `<div class="content"><p>AAAFx reimburses the transaction cost you incur while making a deposit with us maximum upto 5% of the deposit transaction value.</p></div>`,
      },
      {
        label:
          "What happens if I do not make the transactions in my account base currency?",
        content: `<div class="content"><p>When you make a deposit via any currency, funds will show up in your account base currency. The conversion will be done as per the best available market price at that point in time.</p></div>`,
      },
      {
        label: "When can I cancel a deposit request?",
        content: `<div class="content"><p>You can cancel any deposit request by mailing us at support{'@'}aaafx.com or you can come over to our live chat support and we will cancel the request for you.</p></div>`,
      },
      {
        label:
          "Is there any way to initiate a fund transfer to my trading account from another broker?",
        content: `<div class="content"><p>AAAFx does not support fund transfers between brokers. The laid down methods are to be followed to initiate funds transfer.</p></div>`,
      },
      {
        label:
          "Are there any limits on the minimum or maximum withdrawal amount?",
        content: `<div class="content"><p>The minimum withdrawal amount is $10. There is no ceiling limit on withdrawals.</p></div>`,
      },
      {
        label:
          "What is the procedure to withdraw via available funding methods?",
        content: `<div class="content">
        <ul>
        <li>Log into your web account</li>
        <li>Go to Fund tab on left pane</li>
        <li>Click on Withdrawals tab.</li>
        <li>Select the Trading ID from which you wish to make withdrawal.</li>
        <li>Enter the amount to be withdrawn and select the mode of withdrawal.</li>
        <li>Click on Create Transaction.</li>
        </ul>
        </div>`,
      },
      {
        label: "What is the standard withdrawal processing time frame?",
        content: `<div class="content"><p>Withdrawals are usually confirmed in 1 business day. From the moment they are released from your account until they are made available at your designated Bank account, it is a matter of a few business days subject to factors such as the destination country, the use of intermediary bank, etc.</p></div>`,
      },
      {
        label:
          "Can I initiate a withdrawal request through a method other than the one that I originally used to make the deposit?",
        content: `<div class="content"><p>If the deposit source is invalid due to some reason, you may reach us for the relevant action to be taken either over the live chat or email us at <a href="mailto:contactus{'@'}aaafx.com">contactus{'@'}aaafx.com.</a></p></div>`,
      },
      {
        label: "Do you process withdrawals over the weekend?",
        content: `<div class="content"><p>Yes, we process the withdrawals over the weekend.</p></div>`,
      },
      {
        label:
          "Are there any circumstances under which a withdrawal might get denied?",
        content: `<div class="content">
        <p>Yes, a withdrawal request might get denied. The possible reasons might include:</p>
        <ul>
        <li>Insufficient free margin</li>
        <li>Incorrect withdrawal details</li>
        </ul>
        <p>There might be other reasons which will be specified in case the withdrawal is denied.</p>
        </div>`,
      },
      {
        label: "When can I cancel a withdrawal request?",
        content: `<div class="content"><p>You can cancel any withdrawal request on your own, by e-mailing us at <a href="mailto:support{'@'}aaafx.com">support{'@'}aaafx.com</a>  or you can reach out to us at our Live Chat Support and we will cancel the request for you</p></div>`,
      },
      {
        label: "Are there any withdrawal conditions?",
        content: `<div class="content"><p>The withdrawal request shall be raised in the same funding method and of the same amount the deposit was made.</p><p>For eg; if a deposit of the amount of $50 was made using a bank card and $100 using a bank wire. Then, in this case, you can withdraw $50 using the bank card and $100 using the bank wire. You cannot initiate a withdrawal of more than $50 using the bank card or more than $100 using the bank wire.</p><p>In addition, when the withdrawn amount exceeds the amount deposited by a channel other than wire transfer (for example Instant Deposit via credit card or Moneybookers) the remaining withdrawal amount will be sent out by wire transfer.</p></div>`,
      },
      {
        label: "Can I withdraw my money during open trades?",
        content: `<div class="content"><p>You can only withdraw amounts up to the free margin available in your trading accounts. It is usually recommended to request withdrawal while you do not have any open trades.</p><p>However, in case you do have any open trades, you have to examine the most quantity which you would really like to withdraw. In case you do not have sufficient available margin in the trading account, the request can be denied.</p></div>`,
      },
      {
        label: "Do you accept any third-party payments?",
        content: `<div class="content"><p>No, we do not accept any third-party payments.</p></div>`,
      },
      {
        label: "How can I add and reduce funds to and from my demo account?",
        content: `<div class="content">
        <ul>
        <li>1. Log in to your web account.</li>
        <li>2. Go to Fund tab in left pane.</li>
        <li>3. Select Deposits for adding funds into Demo account.</li>
        <li>4. Select Withdrawals for reducing funds from Demo account.</li>
        <li>5. Select the account number and then enter the amount you wish to deposit/withdraw.</li>
        </ul>
        </div>`,
      },
    ],
    "Forex Trading": [
      {
        label: "How to calculate profit/loss?",
        content: `<div class="content">
        <p>When the value of a currency that you have bought rises, then profit is created; you can secure the profit of this transaction by selling the currency back and thus closing the position. Let's see that with an example where we buy U.S. dollars and sell Swiss francs.</p>
        <p>The rate you are quoted is 1.2755 / 1.2760. Because you are buying U.S. dollars you will be working on the "ask" price of 1.2760, or the rate at which traders are prepared to sell.</p>
        <p>Let's assume you buy 1 standard lot (100,000 units) at 1.2760.</p>
        <p>A few hours later, the price moves to 1.2780 and you decide to close your trade.</p>
        <p>The new quote for USD/CHF is 1.2780 / 1.2785. Since you're closing your trade and you initially bought to enter the trade, you now sell in order to close the trade so you must take the "bid" price of 1.2780, or the rate at which traders are prepared to buy.</p>
        <p>The difference between 1.2760 and 1.2780 is .0020 or 20 pips.</p>
        <p>Using the pip calculation formula, we now have (.0001/1.2780) x 100,000 = $7.82 per pip x 20 pips = $156.49 Keep in mind that when you enter or exit a trade you are subject to the spread of the bid/offer quote.</p>
        <p>When you buy a currency, you will use the offer or ask price and when you sell, you will use the bid price.</p>
        </div>`,
      },
      {
        label: "What are AAAFx's trading hours?",
        content: `<div class="content"><p>Market opens on Sunday 22:00 UTC and closes on Friday 22:00 UTC.
      When Eastern time is in daylight saving, market opens on Sunday 21:00 UTC and closes on Friday 21:00 UTC.</p></div>`,
      },
      {
        label:
          "Why do I see prices updating before the market opens but I cannot trade?",
        content: `<div class="content"><p>Even though Market opens on Sunday 00.00 you can notice slight fluctuation of rates prior to opening. The Trading Desk initiates relevant services and starts updating the rates in order to have them prepared and available when the trading resumes. However, the rates displayed prior to the official market open cannot be applied to new market orders and trading cannot be performed.</p></div>`,
      },
      {
        label:
          "How will my open position be affected, if the prices change from Friday's close to Sunday's open?",
        content: `<div class="content"><p>Under normal market conditions when the market opens you can see that the opening price of a currency pair is very close to the price registered on Friday before the market closed. However, there are times when the rates can move significantly up or down, and this can have a positive or a negative effect in your trading activity. If there is a major news announcement or important economic events that will have an impact on the value of the currency, there is a greater likelihood of gaps in the market.</p><p>Please bear in mind that as the price can move against your current position, you can find that your position is losing even though it was in profit when the market closed. For this reason, traders holding positions or orders over the weekend should be aware of such adverse eventual gaps and be ready for potential losses.</p></div>`,
      },
      {
        label:
          "Why did I get a Margin Call notification when the market was closed?",
        content: `<div class="content"><p>The market opening process is not instantaneous - it is a gradual process that starts before 22:00 UTC.</p><p>As a result, we also start receiving rates before the 22:00 point, so we generate a Margin Call or Stop Out notifications before that - however trades are not closed before official market opening at 22:00.</p><p>If after the market opening, the available rates still put your account below the Stop Out level, then the Stop Out mechanism is triggered, and closure of the trades is initiated.</p></div>`,
      },
      {
        label:
          "The price upon market open exceeded my predetermined Stop/Limit of a trade. What will happen to the trade?",
        content: `<div class="content"><p>If the predetermined Stop value is exceeded when the market opens, the trade will be closed by the prevailing rate at that time. The rate will depend on the liquidity available at that moment. The execution of Stop order at any exceeded rate implies bigger losses than expected.</p><p>However, when the market opens at a rate that exceeds your set a Limit value, the trade will be closed with a higher profit than expected.</p><p>Let's see this with an example:</p><p>You have a BUY EUR/USD position opened at price 1.1150 with Limit set to 1.1350.</p><p>On Friday when the market closed the price of the pair reached 1.1250, and on Sunday when the market opened with the price at 1.1450.</p><p>As you can see the price exceeded the Limit of 1.1350 so the trade would be closed by this new current price bringing you profit of 300 pips instead of the set 200 pips.</p></div>`,
      },
      {
        label: "How can I protect my trades held over the weekend?",
        content: `<div class="content"><p>If you believe that over the weekend the market may be volatile and the rates will move against your open positions, you should anticipate risk. If this trading strategy does not suit you, it is recommended that you get out of a trade by realizing your current profit or loss before the market closes.</p></div>`,
      },
      {
        label: "What is rollover/swap?",
        content: `<div class="content"><p>AAAFx  automatically rolls forward all open positions at 21:00 UTC. Trading is typically suspended for up to 1 minute during the roll process. The amount paid or earned depends on the direction of the open position and the interest rate differential between the two currencies involved. For example, assuming UK interest rates are significantly higher than Japan's, a trader long GBP/JPY (i.e. holding Pounds Sterling), is paid interest upon rollover. Conversely, if a trader is short GBP/JPY (i.e. holding Yen) interest will be debited upon the rollover.</p><p>AAAFx’s  daily rollover rates and detailed reporting of rollover activity is available in the Swap column of your Account History at the MT4 platform. For indicative rollover fees, please check our website.</p></div>`,
      },
      {
        label:
          "What are your Margin Requirements for a Stop-Out (Margin Call)?",
        content: `<div class="content"><p>The level at which a Stop-Out will be triggered in your account is the 70% of the margin requirement, e.g. if the margin requirement is 1,000$ and the equity of the account reaches 700$ then all of the open positions will be closed automatically.</p><p>Please note that AAAFx will issue a Margin Call when your equity reaches the 100% of the margin requirement and notify you by e-mail in order for you to take imminent action to prevent a Stop-Out.</p></div>`,
      },
      {
        label: "How do I avoid a Stop Out (Margin Call)?",
        content: `<div class="content">
        <p><b>Sufficient Margin</b></p>
        <p>In general, in order to avoid a Stop Out you should make sure you always have sufficient margin to maintain your current positions open and withstand their potential drawdown. As long as your equity is more than 70% of the margin used for your currently open positions, a Stop-Out will not occur in your account. Please note that you may monitor your Margin Level from your MT4 at any time; the higher the percentage of your Margin Level, the less likely you are to have a Stop Out.</p>
        <p>Please note that AAAFx  will issue a Margin Call when your equity reaches 100% of the margin requirement and notify you by e-mail in order for you to take imminent action to prevent a Stop-Out. Moreover, at 100% Margin Level the information line at the Terminal of your MT4 account will be highlighted from grey to red.</p>
        <p><b>Closing Open Positions</b></p>
        <p>A quick way to increase your Margin Level and reduce your chances of a Stop-Out is to close out some positions, so that the margin that was used to open them will be released back into your Equity.</p>
        <p><b>Fund Increase</b></p>
        <p>Another way of avoiding over-exposure, increasing your Margin Level and thus reducing your chance of a Stop-Out is by depositing more funds. You may use the Instant Deposit method to make immediate capital installations to your account. To do this, simply visit the Funds tab under your Client Login section.</p>
        <p><b>Avoid Overleveraging</b></p>
        <p>Another factor that may put your account at the risk of a Stop-Out is overleveraging. Using more leverage can potentially magnify your gains, but it can also magnify losses which will quickly deplete your usable margin. The more leverage you use, the faster your losses may accumulate.</p>
        <p>When you use excessive leverage, the fact that your account has more trading potential may easily lead you to overexpose it and thus cause you losses. To clearly see how this may happen, consider the following example:</p>
        <p>Trader A has an account with $500 equity and uses a leverage of 200:1,while Trader B has an account with $500 and 100:1 leverage.</p>
        <p>Trader A can trade up to 100,000 currency units and decides to trade 60% of his capital, so he opens 6 trades of 0.1 standard lots each (total of $60,000), and thus uses $300 of the margin ($50 x 0.6 lots) retaining $200 more as free margin. Every pip moving in this trade size is equal to $1, so if all open trades reach a low of 290 pips (loss of approximately 48 pips each trade), his Equity will be $210. When that happens, his margin level will be 70% (210/300), and hence a Stop Out will occur and close all open trades in his account.</p>
        <p>Trader B on the other hand can trade up to 50,000 currency units and decides to trade 60% of his capital. As in this case a 0.1 standard lot requires $100 margin, half of the capital is adequate for 3 trades of 0.1 standard lot each (total of $30,000). Again, every pip moving in this trade size is equal to $1, so if all open trades reach a low of 48 pips, the total low will be 144, hence his Equity will be 356. When that happens, his margin level will be 118% (356/300) so he will not be in danger of a Stop Out.</p>
        <p>In conclusion leverage is a tool that can enlarge your profits or losses by the same magnitude; the greater the amount of leverage on capital you apply, the higher the risk that you assume.</p>
        </div>`,
      },
      {
        label:
          "Why was my trade affected although I see on the MT4 chart that the critical rate was not reached?",
        content: `<div class="content">
        <p>Please note that the MT4 graphs show only Bid prices and not the Ask prices (the difference between them is the spread).</p>
        <p>Please also bear in mind that when you open a Sell trade, it opens at the Bid (Buy) price and closes at the Ask (Sell) price, while when you open a Buy trade it opens at the Ask (Sell) price and closes at the Bid (Buy) price.</p>
        <p>However, you can always see the exact rates your account is trading at from the Market Watch window of your MT4.</p>
        </div>`,
      },
      {
        label: "Does slippage occur with AAAFx  clients' orders?",
        content: `<div class="content">
        <p>Slippage refers to the difference between the requested price of an order and the price at which the order is actually executed. There are market events/conditions that cause high volatility and thus make slippage more likely to occur, such as market news or announcements.</p>
        <p>In such cases, AAAFx  fills the orders at the best available price, based on the requested price and depending on the amount as well as the type of the orders. This can cause an order to produce results that are more favorable, equal to or less favorable than original expectations with the results being referred to as positive slippage, no slippage and negative slippage, respectively.</p>
        </div>`,
      },
      {
        label: "Does AAAFx provide Negative balance protection?",
        content: `<div class="content"><p>AAAFx  operates on a 'negative balance protection' basis which means that the client cannot lose more than his/her overall invested capital (deposit). AAAFx  determines at its sole discretion and in good faith, that the occurrence of such negative balance is not due to any malicious or abusive behavior of the Customer and has not been intentionally caused by the Customer.</p></div>`,
      },
    ],
    "Metals and CFDs": [
      {
        label: "What is the minimum lot size for trading Metals and CFDs?",
        content: `<div class="content"><p>The minimum lot size for Metals and CFDs is 1 standard lot.</p></div>`,
      },
      {
        label:
          "What is one standard lot of gold (XAU/USD) and silver (XAG/USD) equal to?",
        content: `<div class="content"><p>1 standard lot of gold (XAU/USD) is equal to 1 ounce (oz) of gold and 1 standard lot of silver (XAG/USD) is equal to 50 ounces (oz) of silver.</p></div>`,
      },
      {
        label:
          "What is the margin required to trade in CFDs and what are their trading hours?",
        content: `<div class="content"><p>Please find all information about CFDs in the Glossary, CFDs section.</p></div>`,
      },
      {
        label:
          "What is the margin required to trade in gold (XAU/USD) and silver (XAG/USD) at my AAAFx  account?",
        content: `<div class="content"><p>The margin required for gold (XAU/USD) is 100% of the current price and the pip value is $0.01. The margin required for silver (XAG/USD) is 200% of the current price multiplies with Contract size (multiplier) and the pip value is $0.5.</p></div>`,
      },
      {
        label:
          "Why was my USOilSpot/UKOil/NGAS/BUND/Copper trade closed? The stop or limit was not reached and I didn't receive a Margin Call either.",
        content: `<div class="content"><p>German bonds have expiration dates, as per the table in the Commodities and Stocks Section, Expiration dates. As a result, clients will realize any floating P/L at the time the position is closed due to expiration.</p></div>`,
      },
      {
        label:
          "What is the margin required to trade in BTC/USD at my AAAFx account?",
        content: `<div class="content"><p>The margin required for Bitcoin (BTC/USD) is 1000% of the current price and the pip value is $0.01.</p></div>`,
      },
      {
        label: "What is the commission charged for a BTC/USD contract?",
        content: `<div class="content"><p>The commission is  0.0008*Price in ECN Plus account.</p></div>`,
      },
      {
        label: "What is the minimum contract size for trading BTC/USD?",
        content: `<div class="content"><p>There is a minimum of 1 contract size = 1 BTC. This means that with approx. $35,000 BTC/USD rate, you need $7,000 margin to open (Buy or Sell) the minimum order.</p></div>`,
      },
      {
        label:
          "What is the margin required to trade in ETH/USD on my AAAFx account?",
        content: `<div class="content"><p>The margin required for Ethereum (ETH/USD) is 1000% of the current price.</p></div>`,
      },
      {
        label: "What is the maximum number of ETH/USD contracts per client?",
        content: `<div class="content"><p>There is a limit of 1000 contracts of total exposure (e.g. 500 buy and 500 sell = 1K) per client.</p></div>`,
      },
      {
        label:
          "What is the margin required to trade in LTC/USD at my AAAFx account?",
        content: `<div class="content"><p>The margin required for Litecoin (LTC/USD) is 1000% of the current price.</p></div>`,
      },
      {
        label: "What is the maximum number of LTC/USD contracts per client?",
        content: `<div class="content"><p>There is a limit of 1000 Contracts of total exposure (e.g. 500 buy and 500 sell = 1K) per client.</p></div>`,
      },
    ],
    "Basic Forex Information": [
      {
        label: "What is Forex?",
        content: `<div class="content"><p>The foreign exchange market (Forex, FX, or currency market) is a form of exchange for the global decentralized trading of  currencies. Financial centers around the world function as anchors of trading between a wide range of different types of buyers and sellers around the clock, with the exception of weekends. The foreign exchange market determines the relative values of different currencies</p></div>`,
      },
      {
        label: "How big is the Foreign Exchange Market?",
        content: `<div class="content"><p>The foreign exchange market is the most liquid financial market in the world. The average daily turnover in the global foreign exchange and related markets is continuously growing. According to the 2010 Triennial Central Bank Survey, coordinated by the Bank for  Settlements, average daily turnover was US$3.98 trillion in April 2010 (vs. $1.7 trillion in 1998). Of this $3.98 trillion, $1.5 trillion was spot transactions and $2.5 trillion was traded in outright forwards, swaps and other derivatives.</p></div>`,
      },
      {
        label: "Where is the Foreign Exchange Market based?",
        content: `<div class="content"><p>There is no unified or centrally cleared market for the majority of trades, and there is very little cross-border regulation. Due to the over-the-counter (OTC) nature of currency markets, there are rather a number of interconnected marketplaces, where different currencies instruments are traded. This implies that there is not a single exchange rate but rather a number of different rates (prices), depending on what bank or market maker is trading, and where it is located.</p></div>`,
      },
      {
        label: "Who participates in the market?",
        content: `<div class="content"><p>Unlike a stock market, the foreign exchange market is divided into levels of access. At the top is the interbank market, which is made up of the largest commercial banks and securities dealers. The top-tier interbank market accounts for 53% of all transactions. From there, transactions take place from smaller banks, followed by large multi-national corporations, large hedge funds, and even some of the retail market makers. However, the share of other market participants like brokers, multinational corporations, registered dealers, as well as private investors is rapidly increasing.</p></div>`,
      },
      {
        label: "What is a Lot?",
        content: `<div class="content"><p>A Lot is the standard unit size of a transaction. Typically, for Forex currencies, one standard lot is equal to 100,000 units of the base currency, 10,000 units if it's a mini lot, or 1,000 units if it's a micro lot.</p></div>`,
      },
      {
        label: "What is the Spread?",
        content: `<div class="content"><p>The difference between the Bid and Ask prices is referred to as the Spread. AAAFx offers amongst the lowest spreads in the Forex Market.</p></div>`,
      },
      {
        label: "What is a Pip/Tick?",
        content: `<div class="content"><p>A Pip (or Tick) is the smallest price increment a currency can make. It is the fourth digit in all currency pairs with the exception of the Japanese Yen (JPY) where it is the second digit (For example, 1 pip = 0.0001 for EUR/USD, or 0.01 for USD/JPY.)</p></div>`,
      },
      {
        label: "How do I calculate the value of 1 Pip?",
        content: `<div class="content">
        <p>To express the value in the terms currency, multiply 1 pip with the lot size.</p>
        <ul>
        <li>EUR/USD pip = 0.0001 X 100,000 = $10.00 (for standard lots)</li>
        <li>EUR/USD pip = 0.0001 X 10,000 = $1.00 (for mini lots)</li>
        <li>EUR/USD pip = 0.0001 X 1,000 = $0.1 (for micro lots)</li>
        <li>GBP/JPY pip = 0.01 X 100,000 = 1000 ¥ (for standard lots)</li>
        </ul>
        <p>To convert to the base currency divide by the exchange rate:</p>
        <p>Say EUR/USD exchange rate = 1.4750, then $10.00 / 1.4750 = 6.78€</p>
        <p>To convert to an account's currency (e.g. USD) divide by the exchange rate $/¥:</p>
        <p>If $/¥ = 82.12 then 1000 ¥ / 82.12 = 12.17$</p>
        </div>`,
      },
      {
        label: "What are the seven Major Currency Pairs?",
        content: `<div class="content">
        <p>The seven most liquid pairs are traded against the US dollar with the first four pairs being the majors, followed by three commodity pairs. Other currency pairs are better known as the currency crosses, the most popular crosses being those that include the Euro: EUR/JPY, EUR/GBP and EUR/CHF.</p>
        <p>The major currency pairs are:</p>
        <ul>
        <li>EUR/USD</li>
        <li>USD/JPY</li>
        <li>USD/CHF</li>
        <li>GBP/USD</li>
        </ul>
        <p>The commodity currency pairs are:</p>
        <ul>
        <li>AUD/USD</li>
        <li>NZD/USD</li>
        <li>USD/CAD</li>
        </ul>
        </div>`,
      },
      {
        label: "What is Leverage?",
        content: `<div class="content"><p>Forex Leverage or Leverage simply means the % amount of money you are allowed to borrow from the broker when you open a position. Leverage determines the percentage of a trade's nominal value that is required as margin. In simple terms, you can think Leverage as a loan that fronts you to buy currencies.</p></div>`,
      },
      {
        label: "What is Margin?",
        content: `<div class="content"><p>Typically, in order for the Leverage to be released, Brokers will request a certain amount of the account's actual funds to be allocated per each position (lot) traded. So, margin is the minimum amount which is escrowed in order to open a position. Margin depends on the account's leverage; for example, if the leverage is 1:30 then you are able to trade a position thirty times larger than the escrow amount. In simple terms, you can think of Margin as a good faith deposit required to release the loan (leverage).</p><p>For example, having a leverage of 100:1 (or 1% of the position required) allows you to trade a position worth $100,000 with an equity of just $5,000. This would be possible as the broker would set aside $1,000 as down payment, or "margin", and let you "borrow" the rest. Of course, any losses or gains will be deducted from or added to the remaining cash balance of your account.</p></div>`,
      },
    ],
    "Stock CFDs": [
      {
        label: "How do Stock CFDs Work?",
        content: `<div class="content"><p>Stocks (or shares) of listed companies can be speculated with. Online brokers may offer contracts for difference (CFDs) with different companies' stocks as the underlying assets for these CFDs. When a stock CFD is bought, the buyer does not actually own the stock itself but merely speculates on the price movement of that stock by means of the CFD.</p><p>With stock CFDs (contracts for difference), investors can go long (buy) or go short (sell). With a long position, a rise in price will produce a profit. On the other hand, a short position makes money when the price of a stock moves lower.</p></div>`,
      },
      {
        label: "What Moves the Stock Market?",
        content: `<div class="content"><p>The price of a stock is influenced by supply and demand. The forces that influence supply and demand are manifold - fundamental factors (e.g. earnings per share), investor sentiment, technical factors, news about the particular company or its industry sector, etc.</p></div>`,
      },
      {
        label: "What is the difference between Stocks and Stock CFDs trading?",
        content: `<div class="content"><p>CFD trading mimics share trading with the exception that in a contract for difference, you actually don't own the underlying asset, unlike company shares, where you do. What you are essentially doing with CFD trading is buying a contract between yourself and the CFD provider that, depending on your position as (either 'Short Sell' or 'Buy'), will have an entry price when entering a trade, and an exit price when clearing out your trade with an equal opposite position.</p><p>One of the key differences between trading a CFD long vs buying a security is that you can enjoy larger leverage features. Contracts for difference are traded on margin, meaning there is no need to tie up the full market value of purchasing the equivalent stock position.</p><p>There are also a number of key differences between trading an underlying asset and a CFD:</p>
        <ul>
        <li>CFDs stocks can be traded long or short, and you are not required to deliver the underlying asset in the event of a short sale.</li>
        <li>The investor doesn't own the underlying asset over which the CFD is based, but instead enters a contractual agreement with the CFD broker, to exchange the cash difference in the price between the opening and closing prices of the contract.</li>
        <li>As opposed to holding the underlying asset, a CFD is traded on margin which means that an initial deposit is lodged with the CFD broker, which allows the investor to buy or sell a number of CFDs according to margin computations, which generally allow extra leverage over the stock purchase itself.</li>
        </ul>
        </div>`,
      },
      {
        label: "What is the leverage for Stock CFDs?",
        content: `<div class="content"><p>Like all other Equities Stock CFDs Leverage Level is to 1:5. Example: Let's assume that you buy 5 Facebook CFDs at $400. The margin requirement is 20%, you would only be required to outlay $100, thus leaving you with more money to utilise on other trades.</p></div>`,
      },
      {
        label: "Which Stock CFDs do you offer?",
        content: `<div class="content"><p>At the moment, we offer the most popular stocks from DOW30 and Nasdaq please see a full list <a href="/stocks">here</a></p></div>`,
      },
      {
        label: "What are the trading hours for Stocks CFDs trading?",
        content: `<div class="content"><p>Unlike the Forex market which can basically be traded 24 hours a day, 5 days a week, the global stock markets are only open for about 6.5 to 8.5 hours a day, 5 days a week, depending on the exchange. The times at which different countries' stocks can be traded also differ according to the timezone of each country.</p><p>We support U.S. Stock Market CFDs, thus, we follow the regular Trading Session hours of the U.S. Stock Market (NASDAQ, Dow 30): Workdays 09:30 a.m to 4:00 p.m (Eastern Standard Time -- EST).</p><p>For Greek Stock CFDs the trading hours are: Workdays 08:35-15"00 (UTC)</p></div>`,
      },
      {
        label: "What are Corporate Actions?",
        content: `<div class="content"><p>Corporate actions are events initiated by a publicly traded company that usually have a direct or indirect impact on shareholders and result in material changes to it's stock by affecting price, number of traded shares or other characteristics (e.g. ticker name). Corporate actions include stock splits, dividends, mergers, acquisitions, rights issues and spin-offs. All of these are major decisions that typically need to be approved by the company's board of directors and authorized by its shareholders. Corporate actions are public information and the relevant action dates are available in various online sources.</p></div>`,
      },
      {
        label: "What is a stock dividend?",
        content: `<div class="content"><p>A dividend is a reward that companies pass to their shareholders and it's coming from the company's net profit. Such rewards can either be in the form of cash, cash equivalent, shares, etc. and are mostly paid from the remaining share of profit once essential expenses are met. In the simplest terms, a dividend is an investor's share of a company's profits.</p></div>`,
      },
      {
        label: "Who is entitled to get Dividends?",
        content: `<div class="content"><p>When you buy some stock CFDs in a company, you usually benefit from any dividends that are paid out by the company on those stocks. Stock CFD Investors are entitled to the dividends if they hold the stocks until the ex-dividend date. Depending on the type of the position (long/short), they will either receive or be charged dividends on the payment date.</p><p>If you buy a stock CFD on its ex-dividend date or after, you will not receive the upcoming dividend payment. Instead, the seller in this situation will get the dividend.</p><p>Please note that Stock dividends will be paid to your account with any applicable withholding taxes deducted. The payment will actually appear on your account as additional cash.</p></div>`,
      },
      {
        label: "What is a stock split?",
        content: `<div class="content"><p>When a company declares a stock split, the number of shares of that company increases, but the market cap remains the same.This action, which requires advance approval from the company's board of directors, usually involves the issuance of additional shares to existing stockholders.</p><p>For example ABC company has 4 million shares outstanding and each share has value $10. In this case, the company's total market value is $40 million (4 million x $10/share). After a two-for-one stock split, the firm's number of shares will double to 8 million, while the value of those shares will be cut in half to $5. However, the company's total market capitalization will remain the same at just $40 million (8 million* $5/share).</p></div>`,
      },
      {
        label: "Do I get dividends from Stock CFDs?",
        content: `<div class="content"><p>Stock CFD Investors depending on the type of the position (long/short) are entitled to be credited or charged the dividend payment if they hold a stock position after the end of ex-dividend date. Dividends are public information and the dates can be easily found online.</p></div>`,
      },
      {
        label: "What is the Minimum Trade Size?",
        content: `<div class="content"><p>The minimum trade size is 1 lot per order which represents one stock.</p></div>`,
      },
      {
        label: "Which is the official Holiday Schedule for US Stocks?",
        content: `<div class="content"><p>Please see below the days that US Market is officially closed:</p>
        <ul>
        <li>Good Friday</li>
        <li>Memorial Day</li>
        <li>Independence Day</li>
        <li>Labor Day</li>
        <li>Thanksgiving Day</li>
        <li>Christmas Friday</li>
        </ul>
        </div>`,
      },
    ],
    "Trading Platforms - General": [
      {
        label: "What prices does the chart on the trading platform show?",
        content: `<div class="content"><p>The charts on the trading platform show bid prices by default. However, you can view the ask line by following the steps below:</p>
        <ul>
        <li>Right-click anywhere on your chart and select Properties.</li>
        <li>Click on the Common tab and check the Show Ask line box.</li>
        <li>Click on the OK button and you will see the Ask line appear on the MT4 chart.</li>
        </ul>
        </div>`,
      },
      {
        label:
          "How can I generate my trading account statement on the trading platform?",
        content: `<div class="content"><p>Kindly follow the steps below to download the trading account statement from the trading platform:</p>
        <ul>
        <li>Login to your trading account</li>
        <li>Click on the "Account history" tab</li>
        <li>Right-click on the blank area and then click on " all history"</li>
        <li>Once the complete history pops up, right-click and click on "Save as detailed report"</li>
        </ul>
        <p>The statement will get downloaded on your device.</p>
        </div>`,
      },
      {
        label:
          "How do I hide or show more pairs/ instruments in the market watch window?",
        content: `<div class="content">
        <ul>
        <li>Right-click on any instrument</li>
        <li>Choose the “symbols” option</li>
        </ul>
        <p>From among the list of forex, indices and commodities choose whichever pair you want to hide or show.(Shortcut: Ctrl U)</p>
        </div>`,
      },
      {
        label: "How do I see details about a trading instrument?",
        content: `<div class="content"><p>To see the details about a trading instrument :</p>
        <ul>
        <li>Right-click on the trading instrument</li>
        <li>Choose “Specification”.</li>
        </ul>
        <p>You can check through the contract specification dialogue box for relevant information like spread, contract size, swap points, etc</p>
        </div>`,
      },
      {
        label: "How can I Activate one-click trading on the platform?",
        content: `<div class="content"><p>Shortcut: Alt T or Right-click on the chart and enable One-Click Trading</p></div>`,
      },
      {
        label: "What is an Investor Password? Why do I need it?",
        content: `<div class="content"><p>The investor password is another name for 'read-only password'. The investor password comes into play when you want a third party to view trades in your account without carrying on any other activity. To generate the investor password, log in to your trading account. Click on Tools>Options>Server and then select Change.</p><p>You may change/generate the investor password directly from your web account as well. Follow the below-given steps:</p><ol><li>Login to your web account</li><li>Select 'Manage Accounts' from the left pane</li><li>Click on 'Reset Trading Password' > 'Investor Password'</li></ol></div>`,
      },
      {
        label: "Is there any way to change the time on my charts?",
        content: `<div class="content"><p>The timings on the charts on trading platforms are according to the server time. At AAAFx, the server time is set to UTC OR UTC+1 depending on daylight savings.</p></div>`,
      },
      {
        label:
          "Can I customize my charts on the trading platform? If yes, how?",
        content: `<div class="content"><p>Yes, charts can be customized by following the steps below:</p>
        <ul>
        <li>Right-click on the chart window.</li>
        <li>Select the option 'Properties'</li>
        <li>You can change the chart colours as per your choice.</li>
        </ul>
        <p>For other modifications, select the 'Common' tab.</p>
        </div>`,
      },
      {
        label:
          "Can I log in to more than one trading account at the same time?",
        content: `<div class="content"><p>Yes, you can log in to multiple trading accounts on a single trading platform. However, trading can only be carried out on one trading account at one time.</p></div>`,
      },
    ],
    "Trading Platforms - MT4": [
      {
        label: "How to log in to my MT4 trading account?",
        content: `<div class="content"><p>To log in to the MT4 platform please follow these steps</p>
        <ul>
        <li>Click on the “File” option in the menu bar</li>
        <li>Select login to trade account</li>
        <li>Fill in your trading id and password</li>
        <li>Select the server name: AAAFx-FX-Demo (For Demo Account) / AAAFx-FX-Real (For Real Account)</li>
        <li>Click on login</li>
        </ul>
        <p>You'll be logged into your trading account</p>
        </div>`,
      },
      {
        label: "How do I log in to a real account on MT4?",
        content: `<div class="content"><p>To login to a real account on the MT4 platform, please follow these steps:</p>
        <p>First, you need to create a real account on the portal. To create a live account please follow these steps:</p>
        <ul>
        <li>Register: Click on the “Join now” option on the portal</li>
        <li>Enter your details: Name, email ID, phone number</li>
        <li>Enter the verification code you would have received in your mail</li>
        <li>Choose platform, account type, account's base currency , leverage and lastly set the password to the account.</li>
        <li>Further, you need to upload documents</li>
        <li>Now deposit funds</li>
        </ul>
        <p>After this, follow these steps to log in to the platform:</p>
        <ul>
        <li>Click on the “File” option in the menu bar</li>
        <li>Choose “Login to a trade account”</li>
        <li>Enter your trading ID and password</li>
        <li>Select Server: AAA Fx-FX-Real</li>
        <li>Click on 'Login'</li>
        </ul>
        </div>`,
      },
      {
        label: "How do I create/log in to a demo account?",
        content: `<div class="content"><p>To create a demo account, please follow these steps:</p>
        <ol>
        <li>Log in to your web account.</li>
        <li>Go to "Manage Accounts"</li>
        <li>Click on Create a Demo account"</li>
        <li>Choose the trading platform, account leverage, account's base currency and set password.</li>
        </ol>
        <p>Now a demo account has been created for you.</p>
        </div>`,
      },
      {
        label: "Where can I view my commissions?",
        content: `<div class="content"><p>To see commission, please follow these steps:</p>
        <ul>
        <li>Click on the “View” option in the menu bar</li>
        <li>Choose “Terminal”</li>
        <li>Select “Trade Section”</li>
        <li>Right-click on the trade placed</li>
        <li>Enable commissions </li>
        </ul>
        <p>You can now see commissions as a separate column</p>            
        </div>`,
      },
      {
        label:
          "How can I change my trading account password from the platform?",
        content: `<div class="content"><p>Follow these steps to change the account password from MT4:</p>
        <ul>
        <li>Click on “Tools” in the menu bar</li>
        <li>Select “Options” > Dialogue Box will appear</li>
        <li>Enter new password > Okay</li>
        </ul>
        <p>OR</p>
        <ul>
        <li>In the navigation window, under accounts > Right-click on the account that you want to change the password for</li>
        <li>Enter the current password and new password > Click on Okay</li>
        </ul>
        </div>`,
      },
      {
        label: "How can I delete/ remove my account from the MT4 platform?",
        content: `<div class="content"><p>To remove or delete your account from the MT4 platform, Right-click on the account that you need to remove and just select the “Delete” option.</p></div>`,
      },
      {
        label: "From where can I download the MT4 platform?",
        content: `<div class="content"><p>To download the platform please follow these steps:</p>
        <ul>
        <li>Click on the “Platform” tab on the portal</li>
        <li>Click on “Download here”</li>
        </ul>
        </div>`,
      },
      {
        label: "Am I allowed to use an EA/ BOT/ Trading Robot?",
        content: `<div class="content"><p>You can definitely use an Expert Advisor in MT4. Check for the ones that are freely available in the navigation window. If you want you can also purchase EA's from the MQL 5 website.</p></div>`,
      },
      {
        label: "How can I attach EA in MT4?",
        content: `<div class="content"><p>To attach an EA, please follow these steps:</p>
        <ul>
        <li>Open Navigator Box (Ctrl N)</li>
        <li>Expert Advisor</li>
        <li>Right-click on EA that you want to use</li>
        <li>Attach to chart </li>
        </ul>
        <p>And in case you are willing to apply for a purchased EA from the MQL site, please follow these steps:</p>
        <ul>
        <li>Go to the “File” tab in the menu bar</li>
        <li>Click on Open Data Folder </li>
        <li>Open the “Experts” folder in the “MQL” file.</li>
        <li>Open the “Experts” folder in the “MQL” file.</li>
        <li>Reopen the MT4 platform again.</li>
        </ul>
        </div>`,
      },
      {
        label: "How do I set up default charts with indicators on MT4?",
        content: `<div class="content"><p>Please follow the steps below:</p>
        <p><b>To Save A Profile:</b></p>
        <p>Once you have everything positioned the way that you want, please go to File - Profiles and click “Save As” Enter a profile name and then press “Ok”</p>
        <p>To load a saved profile, please go to File - Profiles and then select the profile from the list of profiles that comes up. The profile should load.</p>
        <p><b>To Save A Template:</b></p>
        <p>Once you have everything set up on your chart the way you want, please go to Charts - Template - Save Template to save the Template. Enter a name for the template and press save.
        </p>
        </div>`,
      },
      {
        label: "Can I use multiple trading accounts in MT4?",
        content: `<div class="content"><p>Yes, you can use multiple trading accounts in MT4, to add an account follow these steps:</p>
        <ul>
        <li>Right-click on accounts in the navigator box</li>
        <li>Choose “Login to trading account”</li>
        <li>Fill in your trading ID and password</li>
        </ul>
        <p>Your trading account will be inserted in MT4.</p>
        </div>`,
      },
      {
        label: "Where can I check my trading account connection status?",
        content: `<div class="content"><p>Connection status can be checked from the bottom right corner below the terminal window.</p><p>To make the connection status visible, click on “view” > enable status bar</p></div>`,
      },
    ],
    "Trading Platforms - MT5": [
      {
        label: "How to log in to my MT5  trading account?",
        content: `<div class="content"><p>To log in to the MT5 platform please follow these steps</p>
        <ul>
        <li>Click on the “File” option in the menu bar</li>
        <li>Select login to trade account</li>
        <li>Fill in your trading id and password</li>
        <li>Select the server name: AAAFx-FX-Demo (For Demo Account) / AAAFx-FX-Real (For Real Account)</li>
        <li>Click on login</li>
        <p>You'll be logged into your trading account</p>
        </ul>
        </div>`,
      },
      {
        label: "How do I log in to a real account on MT5?",
        content: `<div class="content"><p>To login to a real account on the MT5 platform, please follow these steps</p>
        <p>First, you need to create a real account on the portal. To create a live account please follow these steps:</p>
        <ul>
        <li>Register: Click on the “Join now” option on the portal</li>
        <li>Enter your details: Name, email ID, phone number</li>
        <li>Enter the verification code you would have received in your mail</li>
        <li>Choose account base currency and trading platform</li>
        <li>Further, you need to upload documents</li>
        <li>Now deposit funds.</li>
        </ul>
        <p>After this, follow these steps to log in to the platform:</p>
        <ul>
        <li>Click on the “File” option in the menu bar</li>
        <li>Choose “Login to a trade account”</li>
        <li>Enter your trading ID and password</li>
        <li>Select Server: AAA Fx-FX-Real</li>
        <li>Click on 'Login'</li>
        </ul>
        </div>`,
      },
      {
        label: "How do I create/log in to a demo account?",
        content: `<div class="content"><p>To create a demo account, please follow these steps:</p>
        <ol>
        <li>Log in to your web account.</li>
        <li>Go to "Manage Accounts"</li>
        <li>Click on Create a Demo account"</li>
        <li>Choose the trading platform, account leverage, account's base currency and set password.</li>
        </ol>
        <p>Now a demo account has been created for you.</p>
        </div>`,
      },
      {
        label: "Where can I view my commissions?",
        content: `<div class="content"><p>To see commission, please follow these steps:</p>
        <ul>
        <li>Click on the “View” option in the menu bar</li>
        <li>Choose “Terminal”</li>
        <li>Select “Trade Section”</li>
        <li>Right-click on the trade placed</li>
        <li>Enable commissions</li>
        </ul>
        <p>You can now see commissions as a separate column</p>
        </div>`,
      },
      {
        label:
          "How can I change my trading account password from the platform?",
        content: `<div class="content"><p>Follow these steps to change the account password from MT5:</p>
        <ul>
        <li>Click on “Tools” in the menu bar</li>
        <li>Select “Options” > Dialogue Box will appear</li>
        <li>Enter new password > Okay</li>
        </ul>
        <p>OR</p>
        <ul>
        <li>In the navigation window, under accounts > Right-click on the account that you want to change the password for</li>
        <li>Enter the current password and new password > Click on Okay</li>
        </ul>
        </div>`,
      },
      {
        label: "How can I delete/ remove my account from the MT5 platform?",
        content: `<div class="content"><p>To remove or delete your account from the MT5 platform, Right-click on the account that you need to remove and just select the “Delete” option.</p></div>`,
      },
      {
        label: "From where can I download the MT5 platform?",
        content: `<div class="content"><p>To download the platform please follow these steps:</p>
        <ul>
        <li>Click on the “Platform” tab on the portal</li>
        <li>Click on “Download here”</li>
        </ul>
        </div>`,
      },
      {
        label: "Am I allowed to use an EA/ BOT/ Trading Robot?",
        content: `<div class="content"><p>You can definitely use an Expert Advisor in MT5. Check for the ones that are freely available in the navigation window. If you want you can also purchase EA's from the MQL 5 website.</p></div>`,
      },
      {
        label: "How can I attach EA in MT5?",
        content: `<div class="content"><p>To attach an EA, please follow these steps:</p>
        <ul>
        <li>Open Navigator Box (Ctrl N)</li>
        <li>Expert Advisor</li>
        <li>Right-click on EA that you want to use</li>
        <li>Attach to chart </li>
        </ul>
        <p>And in case you are willing to apply for a purchased EA from the MQL site, please follow these steps:</p>
        <ul>
        <li>Go to the “File” tab in the menu bar</li>
        <li>Click on Open Data Folder </li>
        <li>Open the “Experts” folder in the “MQL” file.</li>
        <li>Open the “Experts” folder in the “MQL” file.</li>
        <li>Reopen the MT4 platform again.</li>
        </ul>
        </div>`,
      },
      {
        label: "How do I set up default charts with indicators on MT5?",
        content: `<div class="content"><p>Please follow the steps below:</p>
        <p><b>To Save A Profile:</b></p>
        <p>Once you have everything positioned the way that you want, please go to File - Profiles and click “Save As” Enter a profile name and then press “Ok”</p>
        <p>To load a saved profile, please go to File - Profiles and then select the profile from the list of profiles that comes up. The profile should load.</p>
        <p><b>To Save A Template:</b></p>
        <p>Once you have everything set up on your chart the way you want, please go to Charts - Template - Save Template to save the Template. Enter a name for the template and press save.
        </p>
        </div>`,
      },
      {
        label: "Can I use multiple trading accounts in MT5?",
        content: `<div class="content"><p>Yes, you can use multiple trading accounts in MT5, to add an account follow these steps:</p>
        <ul>
        <li>Right-click on accounts in the navigator box</li>
        <li>Choose “Login to trading account”</li>
        <li>Fill in your trading ID and password</li>
        </ul>
        <p>Your trading account will be inserted in MT5.</p>
        </div>`,
      },
      {
        label: "Where can I check my trading account connection status?",
        content: `<div class="content"><p>Connection status can be checked from the bottom right corner below the terminal window.</p><p>To make the connection status visible, click on “view” > enable status bar</p></div>`,
      },
    ],
    "Trading Platforms - ActTrader": [
      {
        label:
          "How do I log in to my trading account on the ACT on the desktop?",
        content: `<div class="content"><p>Kindly follow the steps below to login into your trading account:</p>
        <ul>
        <li>Click on the <a href="https://aaafx.com/act-trader" target="_blank">link</a> to download the trading platform.</li>
        <li>A setup will be downloaded on the system. Click on the setup and the platform will get installed.</li>
        <li>After the platform is installed, enter the username and password for the trading account.</li>
        <li>Select the server- 'Demo' for a demo account and 'Real' for a live account.</li>
        <li>Click on 'Login'. You are logged in to your account.</li>
        </ul>
        </div>`,
      },
      {
        label:
          "How do I log in to my trading account on the ACT on web trader?",
        content: `<div class="content"><p>Kindly follow the steps below to login to your trading account on the web trader:</p>
        <ul>
        <li>Click on the <a href="https://aaafx.com/act-trader" target="_blank">link</a> to open web trader</li>
        <li>A new tab will appear.</li>
        <li>Enter the username and password for the trading account.</li>
        <li>Click on 'Login'. You are logged in to your account.</li>
        </ul>
        </div>`,
      },
      {
        label:
          "I am confused on how to install the ACT application on my mobile device. Please help.",
        content: `<div class="content"><p>Kindly follow the steps below to install the application on your mobile device:</p>
        <p><b>ANDROID</b></p>
        <ul>
        <li>Login to your client portal on your mobile device.</li>
        <li>Click on the <a href="https://aaafx.com/act-trader" target="_blank">link</a></li>
        <li>Scroll to the bottom of the window and click on 'ACT ANDROID'.</li>
        <li>Google store opens up an application named 'ActDroid' pops up.</li>
        <li>Click on 'Install' and the application will get installed on your device.</li>
        </ul>
        <p><b>IOS</b></p>
        <ul>
        <li>Login to your client portal on your mobile device.</li>
        <li>Click on the <a href="https://aaafx.com/act-trader" target="_blank">link</a></li>
        <li>Scroll to the bottom of the window and click on ACT IOS.</li>
        <li>Google store opens up an application named 'ActPhone' pops up.</li>
        <li>Click on 'Install' and the application will get installed on your device.</li>
        </ul>
        </div>`,
      },
      {
        label: "How do I log in to my trading account on my mobile device?",
        content: `<div class="content"><p>Kindly follow the steps below to login into your trading account on your mobile device:</p>
        <p><b>ANDROID</b></p>
        <ul>
          <li>Click on the ActDroid application on your device.</li>
          <li>Press 'Agree' on the license agreement.</li>
          <li>Enter the username and password for the trading account.</li>
          <li>In account type, select the server according to the account type- 'AAAFx-FX-Demo' for a demo account and 'AAAFx-FX-Real' for a live account.</li>
          <li>Click on 'Login'. You are now logged in to your account.</li>
        </ul>
        <p><b>IOS</b></p>
        <ul>
          <li>Click on the ActPhone application on your device.</li>
          <li>Enter the username and password for the trading account.</li>
          <li>In account type, select the server according to the account type- 'AAAFx-FX-Demo' for a demo account and 'AAAFx-FX-Real' for a live account.</li>
          <li>Click on 'Login'. You are now logged in to your account.</li>
        </ul>
        </div>`,
      },
      {
        label: "How do I place an order?",
        content: `<div class="content"><p>You can easily make a trade by clicking the Sell or Buy buttons under the Home tab of the FXVoyager Toolbar.</p><p>You can also place a Market order directly from the Dealing Rates window, Instruments window, or the Dealing Rates Table. Simply left-click on the bid or ask or right-click on the rate and choose Market Order.</p></div>`,
      },
      {
        label:
          "How can I check my trading account balance on the trading platform?",
        content: `<div class="content"><p>You can check the account balance in the following way:</p>
        <ul>
        <li>Login to your trading account.</li>
        </li>Click on the 'Summary' tab.
        </ul>
        <p>All the account information including balance will appear.</p>
        <p>OR</p>
        <p>The account balance can be checked on the bottom left corner of the platform.</p>
        </div>`,
      },
      {
        label:
          "On the ACT, can I log in to multiple accounts at the same time?",
        content: `<div class="content"><p>No, ACT does not allow the user to log in to multiple trading accounts at the same time.</p></div>`,
      },
      {
        label: "How can I enable one-click trading?",
        content: `<div class="content">
        <ul>
        <li>Click on the setting's icon on the top left corner</li>
        <li>Select "main setting"</li>
        <li>Checkmark the "One-click trading"</li>
        <li>Click on apply</li>
        </ul>
        </div>`,
      },
      {
        label: "Can I apply indicators on my chart? How?",
        content: `<div class="content"><p>Kindly follow the steps below to apply indicators on your chart:</p>
        <ul>
        <li>Open the chart on which you want to apply the indicator.</li>
        <li>Move your cursor onto the chart. Right-click on the chart area.</li>
        <li>Select 'Set up Indicators'.</li>
        <li>A pop with various indicators will open up.</li>
        <li>Select the indicator and press the forward button " > "</li>
        <li>You can change the settings of the selected indicator on the envelope area before applying.</li>
        <li>Click on "Apply" and then "Ok".</li>
        </ul>
        <p>You will see that the indicator has been applied on your chart.</p>
        </div>`,
      },
      {
        label: "How can I remove an indicator from my chart?",
        content: `<div class="content"><p>Kindly follow the steps below to remove indicators on your chart:</p>
        <ul>
        <li>Open the chart on which you want to remove the indicator.</li>
        <li>Move your cursor onto the chart. Right-click on the chart area.</li>
        <li>Select 'Set up Indicators'.</li>
        <li>A pop with various indicators will open up.</li>
        <li>You can see the list of all applied indicators under "active indicators ".</li>
        <li>Select the indicator and press the reverse button " < "</li>
        <li>Click on "Apply" and then "Ok".</li>
        </ul>
        <p>You will see that the indicator has been removed from the chart.</p>
        </div>`,
      },
      {
        label: "Can I remove multiple indicators from the chart?",
        content: `<div class="content"><p>Yes, you can surely do that.</p>
        <ul>
        <li>Open the chart on which you want to remove the indicator.</li>
        <li>Move your cursor onto the chart. Right-click on the chart area.</li>
        <li>Select 'Set up Indicators'.</li>
        <li>A pop with various indicators will open up.</li>
        <li>You can see the list of all applied indicators under "active indicators ".</li>
        <li>Click the fast reverse button " << " to remove all indicators</li>
        <li>Click on "Apply" and then "Ok".</li>
        </ul>
        </div>`,
      },
      {
        label: "From where can I open the dealing rate window?",
        content: `<div class="content"><p>Kindly follow the steps below to open the dealing rate window:</p>
        <ul>
        <li>Login to your trading account on the platform.</li>
        <li>Click on the "Instrument" tab.</li>
        <li>Move your cursor towards the particular instrument.</li>
        <li>Right-click and select the " Individual dealing rate window".</li>
        <li>A pop-up will open up with the dealing rate of that pair.</li>
        </ul>
        </div>`,
      },
      {
        label: "How can I place an order from the dealing rate window?",
        content: `<div class="content"><p>Kindly follow the steps below to open the dealing rate window:</p>
        <ul>
        <li>Login to your trading account on the platform.</li>
        <li>Click on the "Instrument" tab.</li>
        <li>Move your cursor towards the particular instrument.</li>
        <li>Right-click and select the " Individual dealing rate window".</li>
        <li>A pop-up will open up with the dealing rate of that pair.</li>
        <li>Double click on the pop-up, change the required settings and click on 'OK' to place the order.</li>
        </ul>
        </div>`,
      },
      {
        label:
          "Can I download my trading account statement from the trading platform?",
        content: `<div class="content"><p>Yes, the trading account statement can be downloaded from the platform by following the steps below:</p>
        <ul>
        <li>Login to your trading account on the platform.</li>
        <li>Click on 'Reports'.</li>
        <li>Select 'Account Statement'.</li>
        <li>Select the time format and click on 'Run Report'.</li>
        <li>The report will open up on the screen. Select 'Export to excel'. The report will open on the excel sheet and it can be saved from there.</li>
        </ul>
        </div>`,
      },
      {
        label:
          " I want to modify my Sell order. How can I apply Stop Loss and Take Profit levels to my order?",
        content: `<div class="content"><p>To modify an order, open the 'Trade' tab on the toolbar and click the 'Stop or Limit button'. You can set a Stop or Limit order from the Open Positions window. You can also set a Predefined Stop or Limit in the New Position dialog window.</p></div>`,
      },
      {
        label: "How can I close a trade on the chart?",
        content: `<div class="content"><p>To close or hedge a position, right-click on the position triangle and select the appropriate command.</p></div>`,
      },
      {
        label: "How can I add Trailing Stop on an order?",
        content: `<div class="content"><p>Kindly follow the steps below to add Trailing Stop to your order:</p>
        <ul>
        <li>In the Open Positions window, right-click on the desired position.</li>
        <li>A pop-up menu will appear on the screen.</li>
        <li>Choose the Trailing Stop level and click on 'OK'.</li>
        </ul>
        </div>`,
      },
      {
        label: "Where can I see my trading history?",
        content: `<div class="content"><p>The position history can be viewed in the Closed Positions window.</p></div>`,
      },
      {
        label: "How can I view the trading history on IOS?",
        content: `<div class="content"><p>To view history on Ios, click on Menu > History. By default, up to 15 positions can be viewed. The number of positions can be increased by opening Menu > Settings.</p></div>`,
      },
      {
        label: "How can I open a chart of a trading instrument?",
        content: `<div class="content"><p>To open a chart window on your trading workspace, choose 'Charts' on the Upper Panel Menu and then choose 'New Chart'.</p>
        <p>OR</p>
        <p>Please right-click on an instrument in the Dealing Rates window and select Open Chart. Select the instrument (if you right-clicked on an instrument to reach the New Chart dialog, then that instrument will be pre-selected) and the time scale from the New Chart menu and press OK.
        </p>
        </div>`,
      },
      {
        label: "How can I zoom in and zoom out the chart?",
        content: `<div class="content"><p>On the top of the chart, the plus '+' and minus '-' icons allow the zoom in, zoom out, and default chart display.</p></div>`,
      },
      {
        label: "Can I change the colours of the chart?",
        content: `<div class="content"><p>Yes, you can change the colours of the chart. Right-click on the chart and select 'Chart Settings'. You will see the 'Colors' drop-down menu. The colours can be changed accordingly.</p></div>`,
      },
      {
        label: "Where do I see the price line on the chart?",
        content: `<div class="content"><p>The price line is the blue coloured line in the chart background. It moves as per the price movement of the instrument.</p></div>`,
      },
    ],
    "Trading Conditions": [
      {
        label:
          "Are there any kind of restrictions on adopting trading styles? I am a scalper.",
        content: `<div class="content"><p>Being a trader-friendly broker, we don't restrict any trading styles. You can do hedging, scalping, and use EAs on your trading platform.</p></div>`,
      },
      {
        label: "Do you follow the FIFO rule for trade execution?",
        content: `<div class="content"><p>No, we don't abide by the FIFO rule. The trader can close the trades as per his own strategy.</p></div>`,
      },
      {
        label: "What type of trade execution do you provide?",
        content: `<div class="content"><p>At AAAFx, the trade execution is instant.</p></div>`,
      },
      {
        label:
          "Is there a limit on the number of trades to be placed every month?",
        content: `<div class="content"><p>No, there are no such limits. You can place trades as per your own choice.</p></div>`,
      },
      {
        label: "Can I trade over the weekend?",
        content: `<div class="content"><p>No, trading is not available on the weekends.</p></div>`,
      },
      {
        label: "For how long can I hold my open positions?",
        content: `<div class="content"><p>There is no compulsion on the duration for which a trade can be kept open. You can keep it open as long as you have sufficient free margin in the account.</p></div>`,
      },
      {
        label: "Can I lose more than what I deposit when trading with you?",
        content: `<div class="content"><p>While trading with AAAFx, you cannot lose more than what you deposit because we provide Negative Balance Protection. AAAFx determines at its sole discretion and in good faith, that the occurrence of such negative balance is not due to any malicious or abusive behavior of the client and has not been intentionally caused by the client.</p></div>`,
      },
      {
        label: "What if there is no free margin left in my trading account?",
        content: `<div class="content"><p>If there is no free margin left, you can not place any further orders. Also, you can not raise a withdrawal request because you can always withdraw the amount available as a free margin in your account.</p></div>`,
      },
      {
        label: "What kind of spreads do you provide at AAAFx?",
        content: `<div class="content"><p>We provide razor-thin spreads to all our clients irrespective of different account types. </p></div>`,
      },
      {
        label: "What leverage is offered by AAAFx?",
        content: `<div class="content"><p>The maximum leverage offered for AAAFX Europe is 30X.</p></div>`,
      },
      {
        label: "What time zone are your servers set at?",
        content: `<div class="content"><p>It is set at UTC Time [UTC +1 during daylight saving].</p></div>`,
      },
      {
        label: "Where are your servers located?",
        content: `<div class="content"><p>Our servers are located in London and New York.</p></div>`,
      },
      {
        label: "Do you offer trading advice to your clients?",
        content: `<div class="content"><p>Being a broker, we don't provide any kind of trading advice to our clients. For the ideal trading strategies, you can refer to the Zulutrade website and learn as much as you can. Furthermore, you can also read about the relationship between leverage and margin to invest your funds wisely. Forex trading is all about investing your time and money in a judicious manner. Start investing now!</p></div>`,
      },
    ],
  },
};
